import React, { useState } from 'react';
import { API_seminarENDPOINTS } from '../config';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { TextField, Button, Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { validateCreateSeminar } from '../utils/validation'
import '../css/seminarForm.css';

function SeminarForm() {

    const user = useRecoilValue(userState);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [seminarPhoto, setSeminarPhoto] = useState('');
    const [genre, setGenre] = useState('');
    const [start_time, setStart_time] = useState('');
    const [end_time, setEnd_time] = useState('');
    const [capacity, setCapacity] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();


    const genres = [
        { value: '1', label: 'ファイナンシャルウェルビーイング' },
        { value: '2', label: 'ライフプランニング' },
        { value: '3', label: '結婚・出産' },
        { value: '4', label: '老後' },
        { value: '5', label: '家計管理と貯金' },
        { value: '6', label: '税金' },
        { value: '7', label: '保険について' },
        { value: '8', label: '住まい' },
        { value: '9', label: '資産運用 基礎編' },
        { value: '10', label: '資産運用 商品編' },
        { value: '11', label: '株式投資' },
    ];

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSeminarPhoto(file);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateCreateSeminar({ title, description, seminarPhoto, genre, start_time, end_time, capacity });
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const form = new FormData();

        form.append('title', title);
        form.append('description', description);
        form.append('seminar_photo', seminarPhoto);
        form.append('genre', genre);
        form.append('start_time', start_time);
        form.append('end_time', end_time);
        form.append('capacity', capacity);

        fetch(API_seminarENDPOINTS.store, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(form)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('サーバーエラー');
            }
            return response.json();
        })
        .then(data => {
            toast.success('登録が完了しました');
            navigate('/seminar');
        })
        .catch(error => {
            toast.error('登録に失敗しました');
        });
    };

    return (
        <>
        <Box className='seminarField'>
        <h2>セミナー作成</h2>
            <Box component="form" onSubmit={handleSubmit} className='seminarForm'>
                <Box className='titleForm'>
                    <TextField
                        label="タイトル"
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        variant="outlined"
                        className='titleField'
                    />
                    {errors.title && <div className="error">{errors.title}</div>}
                </Box>
                <Box className='descriptionForm'>
                    <TextField
                        label="説明"
                        type="text"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        multiline
                        rows={7}
                        className='descriptionField'
                    />
                    {errors.description && <div className="error">{errors.description}</div>}
                </Box>
                <Box className='seminarICatchForm'>
                    <Button
                        component="label"
                        variant="contained"
                        className="seminarCatchBtn"
                    >
                        画像アップロード
                        <input type="file" hidden onChange={handleImageChange} />
                    </Button>
                    {errors.seminarPhoto && <div className="error">{errors.seminarPhoto}</div>}
                    {seminarPhoto && (
                        <>
                            <p className="file-url">選択された画像: {seminarPhoto.name}</p>
                            <Button
                                onClick={toggleModal}
                            >
                                プレビュー
                            </Button>
                        </>
                    )}
                    {isModalOpen && (
                        <div className='seminarModalPreview'>
                            <img src={URL.createObjectURL(seminarPhoto)} alt="プレビュー" className='seminarModalPreviewImg' />
                                <Button
                                    onClick={toggleModal}
                                >
                                    閉じる
                                </Button>
                        </div>
                    )}
                </Box>
                <Box className='timeForm'>
                    <Box>
                        <TextField
                            label="開始時間"
                            type="datetime-local"
                            name="start_time"
                            value={start_time}
                            onChange={(e) => setStart_time(e.target.value)}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className='datetimeField'
                        />
                        {errors.start_time && <div className="error">{errors.start_time}</div>}
                    </Box>
                    <Box>
                        <TextField
                            label="終了時間"
                            type="datetime-local"
                            name="end_time"
                            value={end_time}
                            onChange={(e) => setEnd_time(e.target.value)}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className='endtimeField'
                        />
                        {errors.end_time && <div className="error">{errors.end_time}</div>}
                    </Box>
                </Box>
                <Box className='genreForm'>
                    <FormControl variant="outlined" className='genreField'>
                        <InputLabel id="genre-label">ジャンル</InputLabel>
                        <Select
                            labelId="genre-label"
                            label="ジャンル"
                            name="genre"
                            value={genre}
                            onChange={(e) => setGenre(e.target.value)}
                        >
                            {genres.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {errors.genre && <div className="error">{errors.genre}</div>}
                </Box>
                <Box className='numberForm'>
                    <TextField
                        label="定員"
                        type="number"
                        name="capacity"
                        value={capacity}
                        onChange={(e) => setCapacity(e.target.value)}
                        variant="outlined"
                        className='numberField'
                    />
                    {errors.capacity && <div className="error">{errors.capacity}</div>}
                </Box>
                <Box className='seminarBtnForm'>
                    <Button className='seminarBtn' type="submit" variant="contained" color="primary">
                        予約登録
                    </Button>
                </Box>
            </Box>
        </Box>
        </>
    );
}

export default SeminarForm;