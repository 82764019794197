import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, CardContent, ListItemText, Pagination } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import '../css/searchForm.css';

function SearchForm() {
    const [counselors, setCounselors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const user = useRecoilValue(userState);

    const fetchCounselors = useCallback(async (page) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/counselors?page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const data = await response.json();
            setCounselors(data.data);
            setTotalPages(data.last_page);
            setCurrentPage(data.current_page);
        } catch (error) {
            console.error("エラーが発生しました:", error);
        }
    }, [user.token]);

    useEffect(() => {
        fetchCounselors(currentPage);
    }, [currentPage, fetchCounselors]);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const handleSelectCounselor = (counselor) => {
        if (currentPath.endsWith('/reserve-manage')) {
            navigate(`/calendar/${counselor.id}`);
        } else {
            navigate(`/store-reservation/${counselor.id}`);
        }
    };

    return (
        <div className='searchCounselorBox'>
            <h2>カウンセラー一覧</h2>
            <div className="cardContainer">
                {counselors.map((counselor, index) => (
                <Card className="card" key={index} onClick={() => handleSelectCounselor(counselor)}>
                    <CardContent>
                        <ListItemText primary={counselor.name} secondary={counselor.specialty} />
                    </CardContent>
                </Card>
                ))}
            </div>
            <Box display="flex" justifyContent="center" marginTop={2}>
                <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} />
            </Box>
        </div>
    );
}

export default SearchForm;
