import React, { useState, useEffect, useCallback } from 'react';
import { Box, Card, CardContent, ListItemText, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import '../css/searchForm.css';

function UserSearchForm() {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();
    const user = useRecoilValue(userState);


    const fetchUsers = useCallback(async (page) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/counselors?page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const data = await response.json();
            setUsers(data.data);
            setTotalPages(data.last_page);
            setCurrentPage(data.current_page);
        } catch (error) {
            console.error("エラーが発生しました:", error);
        }
    }, [user.token]);

    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage, fetchUsers]);

        const handleChangePage = (value) => {
        setCurrentPage(value);
    };

    const handleSelectUser = (user) => {
        navigate(`/user/show/${user.id}`);
    };

    return (
        <div className='searchUserBox'>
            <h1>ユーザー一覧</h1>
            <div className="cardContainer">
                {users.map((user, index) => (
                <Card className="card" key={index} onClick={() => handleSelectUser(user)}>
                    <CardContent>
                        <ListItemText primary={user.name} secondary={user.specialty} />
                    </CardContent>
                </Card>
                ))}
            </div>
            <Box display="flex" justifyContent="center" marginTop={2}>
                <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} />
            </Box>
        </div>
    );
}

export default UserSearchForm;