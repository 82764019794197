import React, { useState, useEffect } from 'react';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { userState } from '../../atom';
import '../../css/searchForm.css';

function SearchWriter() {
    const [writers, setWriters] = useState([]);
    const navigate = useNavigate();
    const user = useRecoilValue(userState);

    useEffect(() => {
        const fetchWriters = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/writers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                const data = await response.json();
                setWriters(data);
            } catch (error) {
                console.error("エラーが発生しました:", error);
            }
        };
        fetchWriters();
    }, [user]);

    const handleSelectWriter = (id) => {
        navigate(`/post-video/master/${id}`);
    };

    return (
        <>
            <div className="cardContainer">
                {writers.map((writer) => (
                    <Card className="card" key={writer.id} onClick={() => handleSelectWriter(writer.id)}>
                        <CardContent>
                            <ListItemText primary={writer.name} secondary={writer.specialty} />
                        </CardContent>
                    </Card>
                ))}
            </div>
        </>
    );
}


export default SearchWriter;
