
import React from 'react';
import DeleteButton from './DeleteButton';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import '../../css/showVideo.css';

function VideoDetail({ video, title, className  }) {
    const navigate = useNavigate();

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric', month: 'long', day: 'numeric',
            hour: '2-digit', minute: '2-digit', hour12: true
        };
        return new Intl.DateTimeFormat('ja-JP', options).format(date);
    }

    const handleEditClick = () => {
        navigate(`/edit/${video.id}`);
    };

    return (
        <div className={`video-detail ${className}`}>
            <div className='showVideoSubtitle'>
                <p>{video.subtitle}</p>
                <Button onClick={handleEditClick}>編集</Button>
                <DeleteButton videoId={video.id} />
            </div>
            <div className='showVideoWriter'>
                <p>{video.writer_id}</p>
            </div>
            <div className='showVideoTitle'>
                <p>{title}</p>
            </div>
            <div className='showVideoTag'>
                <p>{video.tag || '関連タグ無し'}</p>
            </div>
            <div className='showVideoStatus'>
                <p>{video.status}(ステータス)</p>
                <p>更新日時: {formatDate(video.updated_at)}</p>
            </div>
        </div>
    );
}

export default VideoDetail;