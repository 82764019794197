

import React from 'react';
import { Button } from '@mui/material';

function CategoryButton({ categoryName, onClick }) {

    const handleClick = () => {
        onClick(categoryName);
    };

    return (
        <Button
        onClick={handleClick}
        className='selectCategoryBtn'>
            {categoryName} /
        </Button>
    );
}

export default CategoryButton;