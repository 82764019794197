import React, { useState } from 'react';
import { TextField, Button, FormControl, Box, FormControlLabel, FormLabel, MenuItem,
        RadioGroup, Radio, Select } from '@mui/material';
import { API_videoENDPOINTS } from '../config';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validateCreateVideoDetail } from '../utils/validation';
import { UseFetchVideos } from '../components/videos/UseFetchVideos';
import Preview from './Preview';
import GetPermalinkPrefix from '../components/videos/GetPermalinkPrefix';
import '../css/movieForm.css';


const MovieForm = () => {

    const user = useRecoilValue(userState);
    const [fullPermalink, setFullPermalink] = useState('');
    const [permalinkSuffix, setPermalinkSuffix] = useState([]);
    const [articleImages, setArticleContentImages] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [movie, setMovie] = useState(null);
    const [subtitle, setSubtitle] = useState('');
    const [freetag, setFreetag] = useState('');
    const [length, setLength] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('draft');
    const [tag, setTag] = useState('');
    const [number, setNumber] = useState('');
    const [article_title, setArticle_title] = useState('');
    const [description, setDescription] = useState('');
    const [points, setPoints] = useState(['']);
    const [isMovieModalOpen, setIsMovieModalOpen] = useState(false);
    const [videodetailPhoto, setVideodetailPhoto] = useState(null);
    const [videodetailPhotoPreviewUrl, setVideodetailPhotoPreviewUrl] = useState(null);
    const [isVideodetailPhotoModalOpen, setIsVideodetailPhotoModalOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [createError, setCreateError] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [activePhotoPreviewIndex, setActivePhotoPreviewIndex] = useState(null);
    const { videos } = UseFetchVideos(user.token);
    const { writerId } = useParams();

    const [articleContent, setArticleContent] = useState([
        { subtitle: '', content: '', article_photo: null, articlePhotoPreviewUrl: null }
    ]);

    const addPointField = () => {
        setPoints([...points, '']);
    };

    const addArticle = () => {
        const newSection = { subtitle: '', content: '', article_photo: null, articlePhotoPreviewUrl: null };
        setArticleContent([...articleContent, newSection]);
        setArticleContentImages([...articleImages, null]);
    };

    const handleArticleChange = (index, field, value) => {
        const updatedContent = articleContent.map((section, idx) => {
            if (idx === index) {
                return { ...section, [field]: value };
            }
            return section;
        });
        setArticleContent(updatedContent);
    };

    const handleMovieChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            // 有効なファイルオブジェクトが存在することを確認
            if (file instanceof File) {
                const tempUrl = URL.createObjectURL(file);
                // movieステートを更新して、ファイルとプレビューURLを保存
                setMovie({
                    file: file,
                    previewUrl: tempUrl,
                });
            }
        }
    };

    const handleVideoDetailPhotoChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setVideodetailPhoto(file);
            const tempUrl = URL.createObjectURL(file);
            setVideodetailPhotoPreviewUrl(tempUrl);
        }
    };

    const handlePhotoPreviewClick = (index) => {
        setActivePhotoPreviewIndex(index);
    };

    const handleClosePhotoPreview = () => {
        setActivePhotoPreviewIndex(null);
    };

    const handleArticlePhotoChange = (index, e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const tempUrl = URL.createObjectURL(file);
            const updatedContent = articleContent.map((section, idx) => {
                if (idx === index) {
                    return { ...section, article_photo: file, articlePhotoPreviewUrl: tempUrl };
                }
                return section;
            });
            setArticleContent(updatedContent);
        }
    };

    const handlePointChange = (index, value) => {
        const newPoints = [...points];
        newPoints[index] = value;
        setPoints(newPoints);
    };

    const toggleMovieModal = () => {
        setIsMovieModalOpen(!isMovieModalOpen);
    };

    const toggleVideoDetailPhotoModal = () => {
        setIsVideodetailPhotoModalOpen(!isVideodetailPhotoModalOpen);
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handlePreview = () => {
        setIsPreviewOpen(true);
    };

    const handleClosePreview = () => {
        setIsPreviewOpen(false);
    };

    const removeArticle = (index) => {
        const updatedContent = articleContent.filter((_, idx) => idx !== index);
        setArticleContent(updatedContent);
    };

    const removePointField = (index) => {
        const newPoints = points.filter((_, idx) => idx !== index);
        setPoints(newPoints);
    };

    const createVideoDetail = async (videoId) => {

        const createFormData = () => {
            const formData = new FormData();

            formData.append('writer_id', writerId);
            formData.append('permalink', fullPermalink);
            formData.append('subtitle', subtitle);
            formData.append('content', content);
            formData.append('tag', tag);
            formData.append('freetag', freetag);
            formData.append('length', length);
            formData.append('status', status);
            formData.append('video_No', number);
       // ファイルがある場合は追加
            if (movie)  {
                formData.append('movie', movie.file);
            }

            if (videodetailPhoto) {
                formData.append('videodetail_photo', videodetailPhoto);
            }
            formData.append('article_title', article_title);
            formData.append('description', description);
            // 記事の内容をHTML形式で追加
            points.forEach((point) => {
                formData.append('points[]', point);
            });
            const articleContentHtml = articleContent.map(section =>
                `<h1>${section.subtitle}</h1><p>${section.content}</p>`
            ).join('');
            formData.append('article_content', articleContentHtml);
            articleContent.forEach((section, index) => {
                if (section.article_photo) {
                    formData.append(`images[${index}]`, section.article_photo);
                }
            });
            return formData;
        };

        const formData = createFormData();

        try {
            const response = await fetch(`${API_videoENDPOINTS.store}/master/${videoId}/detail`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                body: formData
            });

            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
                const data = await response.json();
                toast.success('ビデオの投稿が完了しました');
                return data;
            } else {
                const text = await response.text();
                console.error("Non-JSON Response:", text);
                console.log(response);
                throw new Error('ビデオ詳細の保存に失敗しました: 非JSONレスポンス');
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error('ビデオの投稿に失敗しました');
            throw error;
        }
    };

    const handleErrorSubtitleChange = (e) => {
        const { value } = e.target;
        setSubtitle(value);
        if (errors.subtitle && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, subtitle: '' }));
        }
    };

    const handleErrorLengthChange = (e) => {
        const { value } = e.target;
        setLength(value);
        if (errors.length && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, length: '' }));
        }
    };

    const handleErrorNumberChange = (e) => {
        const { value } = e.target;
        setNumber(value);
        if (errors.number && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, number: '' }));
        }
    };

    const handleErrorArticleTitleChange = (e) => {
        const { value } = e.target;
        setArticle_title(value);
        if (errors.article_title && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, article_title: '' }));
        }
    };

    const handleErrorDescriptionChange = (e) => {
        const { value } = e.target;
        setDescription(value);
        if (errors.description && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, description: '' }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formErrors = validateCreateVideoDetail ({ permalinkSuffix, selectedVideo, movie, subtitle, length, number, article_title, description });
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            const videoId = selectedVideo;
            if (videoId) {
                await createVideoDetail(videoId);
                toast.success('ビデオの投稿が完了しました');
            } else {
                throw new Error('ビデオの投稿に失敗しました');
            }
        } catch (error) {
            setCreateError('既に使用されているパーマリンクです。');
            console.error(error.message);
        }
    };


    return (
    <>
    <form onSubmit={handleSubmit} className='postForm'>
        <Box className='formFlex'>
            <div className='leftForm'>
                <Box className='selectPackageForm'>
                    <Select
                        value={selectedVideo || ''}
                        onChange={(e) => setSelectedVideo(e.target.value)}
                        displayEmpty
                        className='packageField'
                    >
                        <MenuItem value="">
                            <em>講座を選択してください</em>
                        </MenuItem>
                        {videos.map((video) => (
                            <MenuItem key={video.id} value={video.id}>
                                {video.title}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.selectedVideo && <div className="error">{errors.selectedVideo}</div>}
                </Box>
                <Box className='PamalinkForm'>
                <GetPermalinkPrefix
                    selectedVideo={selectedVideo}
                    token={user.token}
                    permalinkSuffix={permalinkSuffix}
                    setPermalinkSuffix={setPermalinkSuffix}
                    setFullPermalink={setFullPermalink}
                    errors={errors}
                    setErrors={setErrors}
                />
                    {errors.permalinkSuffix && <div className="error">{errors.permalinkSuffix}</div>}
                </Box>
                <Box className='lessonForm'>
                <h1>動画</h1>
                    <Box className='videoUploadForm'>
                        <Button
                            variant="contained"
                            component="label"
                            className="uploadButton"
                        >
                            動画のアップロード
                            <input type="file" hidden onChange={(e) => handleMovieChange(e)} />
                        </Button>
                        {movie && movie.previewUrl && (
                            <>
                                <p className="file-url">選択された動画: {movie.file.name}</p>
                                <Button onClick={toggleMovieModal}>
                                    プレビュー
                                </Button>
                            </>
                        )}
                        {isMovieModalOpen && (
                            <div className='modalPreview'>
                                <video controls src={movie.previewUrl} width="100%"></video>
                                    <Button
                                        onClick={toggleMovieModal}
                                    >
                                        閉じる
                                    </Button>
                            </div>
                        )}
                    </Box>
                    {errors.movie && <div className="error">{errors.movie}</div>}
                    <Box className='videoUploadForm'>
                        <Button
                            variant="contained"
                            component="label"
                            className="uploadButton"
                        >
                            サムネイルのアップロード
                            <input type="file" hidden onChange={(e) => handleVideoDetailPhotoChange(e)} />
                        </Button>
                        {videodetailPhoto && (
                            <>
                                <p className="file-url">選択された画像: {videodetailPhoto.name}</p>
                                <Button
                                    onClick={toggleVideoDetailPhotoModal}
                                >
                                    プレビュー
                                </Button>
                            </>
                        )}
                        {isVideodetailPhotoModalOpen && videodetailPhotoPreviewUrl && (
                            <div className='modalPreview'>
                                <img src={videodetailPhotoPreviewUrl} alt="プレビュー" className='modalPreviewImg' />
                                <Button onClick={toggleVideoDetailPhotoModal}>
                                    閉じる
                                </Button>
                            </div>
                        )}
                    </Box>
                    <Box className='lessonForm2'>
                        <div className='movieTitleForm'>
                            <TextField
                                label="タイトル"
                                variant="outlined"
                                value={subtitle}
                                onChange={handleErrorSubtitleChange}
                                className="movieTitleField"
                            />
                        {errors.subtitle && <div className="error">{errors.subtitle}</div>}
                        </div>
                        <TextField
                            label="フリータグ"
                            variant="outlined"
                            value={freetag}
                            onChange={(e) => setFreetag(e.target.value)}
                            className="freeTagField"
                        />
                        <div>
                            <TextField
                                label="長さ(秒)"
                                variant="outlined"
                                value={length}
                                onChange={handleErrorLengthChange}
                                className='movieLengthField'
                            />
                            {errors.length && <div className="error">{errors.length}</div>}
                        </div>
                    </Box>
                    <Box className='lessonForm2'>
                        <TextField
                            label="投稿内容"
                            variant="outlined"
                            multiline
                            rows={6}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            fullWidth
                        />
                    </Box>
                </Box>
            </div>
            <div className='rightForm'>
                <Box className='articleForm5'>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="postBtn"
                >
                    {status === '下書き保存' ? '下書き保存する' : '新規投稿する'}
                </Button>
                </Box>
                {createError && <div className="loginError">{createError}</div>}
                <Button onClick={handlePreview}>プレビュー</Button>
                <Preview
                    isOpen={isPreviewOpen}
                    handleClose={handleClosePreview}
                    formData={{
                        movie: movie,
                        videodetailPhoto: videodetailPhoto,
                        subtitle: subtitle,
                        content: content,
                        article_title: article_title,
                        description: description,
                        articleContent: articleContent,
                        points: points,
                    // 必要に応じて他のデータも渡す
                    }}
                />
                <Box className='statusBox'>
                    <FormControl component="fieldset">
                        <FormLabel
                        component="legend"
                        className='statusText'
                        >
                            ステータス
                        </FormLabel>
                        <RadioGroup
                            aria-label="status"
                            name="status"
                            value={status}
                            onChange={handleChange}
                            className='statusForm'
                        >
                            <FormControlLabel value="下書き保存" control={<Radio />} label="下書き保存" />
                            <FormControlLabel value="非公開" control={<Radio />} label="非公開" />
                            <FormControlLabel value="公開" control={<Radio />} label="公開" />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box className='packageTagForm'>
                    <TextField
                        label="タグ"
                        variant="outlined"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        className='packageTagField'
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        label="順番"
                        variant="outlined"
                        value={number}
                        onChange={handleErrorNumberChange}
                        className='movieNumberField'
                    />
                    {errors.number && <div className="error">{errors.number}</div>}
                </Box>
            </div>
        </Box>
        <Box className='articleForm'>
            <h1>記事</h1>
            <Box className='articleTitleForm'>
                <TextField
                    label="h1(タイトル)"
                    variant="outlined"
                    value={article_title}
                    onChange={handleErrorArticleTitleChange}
                    className="articleTitleField"
                />
                {errors.article_title && <div className="error">{errors.article_title}</div>}
            </Box>
            <Box className='articleDescriptionForm'>
                <TextField
                    label="概要"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={handleErrorDescriptionChange}
                    className="articleTitleField"
                />
                {errors.description && <div className="error">{errors.description}</div>}
            </Box>
            {points.map((point, index) => (
                <div key={index} className='articlePointForm'>
                    <TextField
                        value={point}
                        onChange={(e) => handlePointChange(index, e.target.value)}
                        className='articlePointField'
                        fullWidth
                    />
                    {points.length > 1 && (
                        <Button onClick={() => removePointField(index)}>
                            削除
                        </Button>
                    )}
                </div>
            ))}
            <Button onClick={addPointField}>ポイントを追加</Button>
            <Box className='articleForm1'>
                {articleContent.map((section, index) => (
                    <React.Fragment key={index}>
                        <Box className='articleForm1'>
                            <TextField
                                label="h2(目次)"
                                variant="outlined"
                                value={section.subtitle}
                                onChange={(e) => handleArticleChange(index, 'subtitle', e.target.value)}
                                className="articleTitleField"
                            />
                        </Box>
                        <Box className='articleForm4'>
                            <Button
                                variant="contained"
                                component="label"
                                className="articlePhotoBtn"
                            >
                                写真のアップロード
                                <input type="file" hidden onChange={(e) => handleArticlePhotoChange(index, e)} />

                            </Button>
                            {section.articlePhotoPreviewUrl && (
                                <>
                                    {section.article_photo && <p className="file-url">選択された写真: {section.article_photo.name}</p>}
                                    <Button onClick={() => handlePhotoPreviewClick(index)}>プレビュー</Button>
                                </>
                            )}
                            {activePhotoPreviewIndex === index && (
                                <div className='modalPreview'>
                                <img src={section.articlePhotoPreviewUrl} alt="プレビュー" className='modalPreviewImg' />
                                <Button onClick={handleClosePhotoPreview}>閉じる</Button>
                                </div>
                            )}
                        </Box>
                        <Box className='articleForm2'>
                            <TextField
                                label="p投稿内容([image]を配置した部分に上記で登録した写真を配置)"
                                variant="outlined"
                                multiline
                                rows={10}
                                value={section.content}
                                onChange={(e) => handleArticleChange(index, 'content', e.target.value)}
                                fullWidth
                            />
                        </Box>
                        {index !== 0 && (
                            <Button onClick={() => removeArticle(index)}>
                                このセクションを削除
                            </Button>
                        )}
                    </React.Fragment>
                ))}
                <Box className='addArticleBtnForm'>
                    <Button
                        onClick={addArticle}
                        variant="contained"
                        className="addArticleBtnField"
                    >
                        記事を追加
                    </Button>
                </Box>
            </Box>
        </Box>
    </form>
    </>
    );
};
export default MovieForm;