import React, { useState } from 'react';
import { Button, Box, TextField } from '@mui/material';
import { API_videoENDPOINTS } from '../config';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { toast } from 'react-toastify';
import { validateCreatePackage } from '../utils/validation';
import '../css/packageForm.css';



const PackageForm = () => {

    const user = useRecoilValue(userState);
    const [title, setTitle] = useState('');
    const [tag, setTag] = useState('');
    const [permalink, setPermalink] = useState('');
    const [videoPhoto, setVideoPhoto] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({});


    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setVideoPhoto(file);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateCreatePackage({ title, tag });
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('tag', tag);
            formData.append('permalink', permalink);
            formData.append('video_photo', videoPhoto);

            const response = await fetch(API_videoENDPOINTS.store, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('講座の作成に失敗しました');
            }
            toast.success('ビデオの投稿が完了しました');
                setTitle('');
                setTag('');
                setPermalink('');
                setVideoPhoto(null);
                setErrors({});
            return response.json();
        } catch (error) {
            toast.error('ビデオの投稿に失敗しました');
        }
    };


    return (
    <div className='packageForm'>
        <h2>講座作成</h2>
        <form onSubmit={handleSubmit} className='postForm'>
            <Box className='packageTitleForm'>
                <TextField
                    label="講座タイトル"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className='packageTitleField'
                    fullWidth
                />
            </Box>
            {errors.title && <div className="error">{errors.title}</div>}
            <Box className='packageTagForm'>
                <TextField
                    label="タグ"
                    variant="outlined"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    className='packageTagField'
                    fullWidth
                />
            </Box>
            <Box className='packageTagForm'>
                <TextField
                    label="指定パーマリンク"
                    variant="outlined"
                    value={permalink}
                    onChange={(e) => setPermalink(e.target.value)}
                    className='packageTagField'
                    fullWidth
                />
            </Box>
                {errors.tag && <div className="error">{errors.tag}</div>}
            <Box className='iCatchForm'>
                <Button
                    component="label"
                    variant="contained"
                    className="articleCatchBtn"
                >
                    画像アップロード
                    <input type="file" hidden onChange={handleImageChange} />
                </Button>
                {videoPhoto && (
                    <>
                        <p className="file-url">選択された画像: {videoPhoto.name}</p>
                        <Button
                            onClick={toggleModal}
                        >
                            プレビュー
                        </Button>
                    </>
                )}
                {isModalOpen && (
                    <div className='modalPreview'>
                        <img src={URL.createObjectURL(videoPhoto)} alt="プレビュー" className='modalPreviewImg' />
                            <Button
                                onClick={toggleModal}
                            >
                                閉じる
                            </Button>
                    </div>
                )}
            </Box>
            <Box>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="postBtn"
                >
                    新規投稿する
                </Button>
            </Box>
        </form>
    </div>
    );
};

export default PackageForm;
