
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


// ログインAPI
export const API_loginENDPOINTS = {
    role1: `${API_ENDPOINT}/master/login`,
    role2: `${API_ENDPOINT}/cs/login`,
    role3: `${API_ENDPOINT}/writer/login`,
    role4: `${API_ENDPOINT}/counselor/login`,
};

// 登録API
export const API_registerENDPOINTS =
{
    role1: `${API_ENDPOINT}/master/register`,
    role2: `${API_ENDPOINT}/cs/register`,
    role3: `${API_ENDPOINT}/writer/register`,
    role4: `${API_ENDPOINT}/counselor/register`,
};

export const API_reserveENDPOINTS =
{
    store: `${API_ENDPOINT}/counselor/reservations`,//新規予約作成
    show: `${API_ENDPOINT}/counselor/reservations`,//対象カウンセラの１日の予約一覧
    oneday: `${API_ENDPOINT}/counselors/reservations/oneday`,//押した日にちのスケジュールが見れるようにしたAPI
    index: `${API_ENDPOINT}/counselors/reservations/index`//対象カウンセラの予約一覧
}

export const API_videoENDPOINTS =
{
    store: `${API_ENDPOINT}/video`,//動画投稿(post)
    delete: `${API_ENDPOINT}/video/delete`,//動画削除(delete)
    index: `${API_ENDPOINT}/video`,//動画投稿(get)
    indexDetail: `${API_ENDPOINT}/video/detail`,//動画投稿(get)
    permalink: `${API_ENDPOINT}/parmlinkname`,//パーマリンク取得
    category: `${API_ENDPOINT}/video/category`//カテゴリから動画取得
}

export const API_seminarENDPOINTS =
{
    index: `${API_ENDPOINT}/seminars/index`,//セミナー表示一覧
    store: `${API_ENDPOINT}/seminar`,//セミナー登録
    edit: `${API_ENDPOINT}/seminar`,//セミナー編集
    delete: `${API_ENDPOINT}/seminar`,//セミナー編集
}

export const API_userENDPOINTS =
{
    index: `${API_ENDPOINT}/adminer/users`,//ユーザーの一覧表示
}

export const API_writerENDPOINTS =
{
    index: `${API_ENDPOINT}/writers`,//著作者一覧
    store: `${API_ENDPOINT}/writer/store`,//著作者のプロフィール作成
}

export const API_counselorENDPOINTS =
{
    index: `${API_ENDPOINT}/counselors/index`,// カウンセラー検索
    store: `${API_ENDPOINT}/counselor/store`,//カウンセラのプロフィール作成
}
