import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from '@mui/material';
import '../css/oneday.css';
import { API_reserveENDPOINTS } from '../config';

function Oneday() {
    const today = new Date().toISOString().slice(0, 10);
    const [reservations, setReservations] = useState([]);
    const [date, setDate] = useState(today);

    function formatTime(datetime) {
        const date = new Date(datetime);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const fetchReservations = useCallback(async () => {
        try {
            const response = await fetch(API_reserveENDPOINTS.oneday, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ date: date }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            setReservations(data.reservations);
        } catch (error) {
            console.error("Failed to fetch counselors:", error);
        }
    }, [date]);

    useEffect(() => {
        fetchReservations();
    }, [fetchReservations]);

    return (
        <>
            <div className="container">
                <Box className='counselorTable'>
                    <Box className='selectDateForm'>
                        <TextField
                            label="Select Date"
                            type="date"
                            value={date}
                            onChange={handleDateChange}
                            InputLabelProps={{ shrink: true }}
                            className="selectDateField"
                        />
                    </Box>
                    <Box className='btnForm'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={fetchReservations}
                            className="dateBtnField"
                        >
                            Search
                        </Button>
                    </Box>
                </Box>
                <Paper>
                {reservations.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>担当名</TableCell>
                                <TableCell>予約開始時間</TableCell>
                                <TableCell>予約終了時間</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {reservations.map((reservation, index) => (
                            <TableRow key={index}>
                                <TableCell>{reservation.counselor.counselor_name}</TableCell>
                                <TableCell>{formatTime(reservation.reservation_datetime)}</TableCell>
                                <TableCell>{formatTime(reservation.reservation_endtime)}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    ) : (
                        <Box className='noReserve'>
                            予約がありません
                        </Box>
                )}
                </Paper>
            </div>
        </>
    );
}

export default Oneday;
