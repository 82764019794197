
import React, { useState } from 'react';
import { Typography, Button, Paper, Box, CardMedia, Modal } from '@mui/material';
import { Phone, Person, Info, AccessTime, AssignmentInd } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import EmailIcon from '@mui/icons-material/Email';
import '../css/userDetail.css';


function UserDetail({ reservation, onClose }) {

    const [open, setOpen] = useState(true);
    const [showMemoModal, setShowMemoModal] = useState(false);
    const [memoContent, setMemoContent] = useState('');
    const phoneNumber = reservation.info.phone;
    const user = useRecoilValue(userState);
    const isCounselor = user.role === "role4";


    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    }

    function formatTime(dateString) {
        const date = new Date(dateString);
        return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    const handleOpenMemoModal = () => {
        fetchMemo();
        setShowMemoModal(true);
    };

    const handleCloseMemoModal = () => {
        setShowMemoModal(false);
    };

    const handleSaveMemo = () => {
        const userId = reservation.user.id;
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/notes`;
        const data = { note: memoContent };
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            handleCloseMemoModal();
        })
        .catch(error => {
            console.error('Error saving note:', error);
        });
    };

    const fetchMemo = () => {
        const userId = reservation.user.id;
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/notes`;
        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        })
        .then(response => response.json())
        .then(data => {
            setMemoContent(data[0].note);
        })
        .catch(error => {
            console.error('Error fetching memo:', error);
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="user-detail-modal-title"
            aria-describedby="user-detail-modal-description"
        >
            <div className="modal-backdrop">
                <Paper className="paper">
                    <Box className='userField'>
                        <Box className='nameAndPhotoField'>
                            <Box>
                                <Typography variant="body1" className="nameInfo">
                                    <Person />   {reservation.user.user_name ? reservation.user.user_name : '未設定'}
                                </Typography>
                            </Box>
                            <Box className='photoField'>
                            {reservation.user.profile_photo ? (
                                <CardMedia
                                    className="profile-photo"
                                    image={reservation.user.profile_photo}
                                    title="プロフィール写真"
                                />
                            ) : (
                                <div>プロフィール写真がありません</div>
                            )}
                            </Box>
                        </Box>
                        <Box className='phoneAndBtnField'>
                            <Typography variant="body1" className="phoneInfo">
                                <Phone />
                                <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {phoneNumber}
                                </a>
                            </Typography>
                            <Button variant="contained" className='btnInfo' color="primary" onClick={handleClose}>
                                閉じる
                            </Button>
                        </Box>
                    </Box>

                    <Box className="mainField">
                        <Box className="counselorField">{/* カウンセラー名か本名か */}
                            <Typography variant="body1" className="counselorInfo">
                                <Person /> カウンセラー名: {reservation.counselor.name}
                            </Typography>
                            <Typography variant="body1" className="emailInfo">
                                <EmailIcon />  {reservation.counselor.email}
                            </Typography>
                            <Typography variant="body1" className="dateInfo">
                                <AccessTime /> 予約日: {formatDate(reservation.reservation_datetime)}
                            </Typography>
                            <Typography variant="body1" className="dateTimeInfo">
                                <AccessTime /> 開始時間: {formatTime(reservation.reservation_datetime)}
                            </Typography>
                            <Typography variant="body1" className="endTimeInfo">
                                <AccessTime /> 終了時間: {formatTime(reservation.reservation_endtime)}
                            </Typography>
                            <Modal
                                open={showMemoModal}
                                onClose={handleCloseMemoModal}
                                aria-labelledby="memo-modal-title"
                                aria-describedby="memo-modal-description"
                            >
                                <div className="modal-backdrop">
                                    <Paper className="paper">
                                        <textarea
                                            value={memoContent}
                                            onChange={(e) => setMemoContent(e.target.value)}
                                            className='memoForm'
                                        />
                                        <Button variant="contained" color="primary" onClick={handleSaveMemo}>
                                            保存
                                        </Button>
                                    </Paper>
                                </div>
                            </Modal>
                            {isCounselor && (
                            <Typography
                                variant="body1"
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={handleOpenMemoModal}
                            >
                                メモを追加
                            </Typography>
                            )}
                        </Box>
                        <Box className="userProfileField">
                                <Typography variant="body1" className="roleInfo">
                                    <AssignmentInd />役職： {reservation.user.role}
                                </Typography>
                                <Typography variant="body1" className="info">
                                    <Info /> {reservation.user.profile_detail}
                                </Typography>
                                <Typography variant="body1" className="info">
                                    <Info />ニーズ： {reservation.info.needs}
                                </Typography>
                        </Box>
                        <Box className="questionnaireField">
                            <Typography variant="body1" className="info">
                                <Info /> アンケート情報:
                            </Typography>
                            <Typography variant="body1" className="ageInfo">
                                年齢: {reservation.user.questionnaire?.[0]?.age ? reservation.user.questionnaire[0].age + '歳' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="genderInfo">
                                性別: {reservation.user.questionnaire?.[0]?.gender.選択肢 ? reservation.user.questionnaire[0].gender.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="prefectureInfo">
                                出身: {reservation.user.questionnaire?.[0]?.prefecture.都道府県 ? reservation.user.questionnaire[0].prefecture.都道府県 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="jobInfo">
                                職業: {reservation.user.questionnaire[0]?.job.職業 ? reservation.user.questionnaire[0]?.job.職業 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                退職予定歳: {reservation.user.questionnaire?.[0]?.work_until_age ? reservation.user.questionnaire[0].work_until_age + '歳' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                年収: {reservation.user.questionnaire?.[0]?.income ? reservation.user.questionnaire[0].income + '万円' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                貯金: {reservation.user.questionnaire?.[0]?.savings ? reservation.user.questionnaire[0].savings + '万円' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                支出: {reservation.user.questionnaire?.[0]?.expenses ? reservation.user.questionnaire[0].expenses + '万円' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                扶養の有無: {reservation.user.questionnaire?.[0]?.has_spouse.選択肢 ? reservation.user.questionnaire[0].has_spouse.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                子供の有無: {reservation.user.questionnaire?.[0]?.has_child.選択肢 ? reservation.user.questionnaire[0].has_child.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                子供の人数: {reservation.user.questionnaire?.[0]?.children_count ? reservation.user.questionnaire[0].children_count + '人' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                子供の年齢: {reservation.user.questionnaire?.[0]?.children_age ? reservation.user.questionnaire[0].children_age + '歳' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                将来欲しい子供の人数: {reservation.user.questionnaire?.[0]?.future_child.選択肢 ? reservation.user.questionnaire[0].future_child.選択肢 + '人' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                子供の学校: {reservation.user.questionnaire?.[0]?.university.選択肢 ? reservation.user.questionnaire[0].university.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                大学の通い方: {reservation.user.questionnaire?.[0]?.home.選択肢 ? reservation.user.questionnaire[0].home.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                現在の住まい: {reservation.user.questionnaire?.[0]?.current_situation.選択肢 ? reservation.user.questionnaire[0].current_situation.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                マイホーム購入: {reservation.user.questionnaire?.[0]?.buy_home.選択肢 ? reservation.user.questionnaire[0].buy_home.選択肢 : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                購入予定時期: {reservation.user.questionnaire?.[0]?.mst_myhome ? reservation.user.questionnaire[0].mst_myhome + '年後' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                現在の家賃: {reservation.user.questionnaire?.[0]?.rent ? reservation.user.questionnaire[0].rent + '万円' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                退職金: {reservation.user.questionnaire?.[0]?.retirement_fund ? reservation.user.questionnaire[0].retirement_fund + '万円' : '未回答'}
                            </Typography>
                            <Typography variant="body1" className="incomeInfo">
                                車購入: {reservation.user.questionnaire?.[0]?.buy_car.選択 ? reservation.user.questionnaire[0].buy_car.選択 + '' : '未回答'}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </div>
        </Modal>
    );
}

export default UserDetail;
