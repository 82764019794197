import React from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from './atom';
import { Box } from '@mui/material';
import './css/home.css';

function Home() {
    const user = useRecoilValue(userState);

    // // ユーザー情報の編集処理
    // const handleEdit = (event) => {
    //     event.preventDefault();
    //     // APIリクエストを送信してユーザー情報を更新
    // };

    // // ユーザー情報の削除処理
    // const handleDelete = () => {
    //     if (window.confirm('本当に削除しますか？')) {
    //         // APIリクエストを送信してユーザー情報を削除
    //     }
    // };

    return (
        <Box className='userInfo'>
            <h4 className="welcomeMessage">
                ようこそ
            </h4>
            <h6 className="userName">
                {user.name} 様
            </h6>

            {/* <Box component="form" onSubmit={handleEdit}>
                <Button type="submit" variant="contained" color="primary">
                    編集
                </Button>
            </Box>

            <Button
                onClick={handleDelete}
                variant="outlined"
                color="secondary"
                sx={{ mt: 2 }}
            >
                削除
            </Button> */}
        </Box>
    );
}

export default Home;