import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import jaLocale from '@fullcalendar/core/locales/ja';

const ReservationCalendar = ({ currentView, reservations, reservationsCountByDate, handleDateClick, handleEventClick, handleDatesSet, dayCellDidMount }) => {
    return (
        <FullCalendar
            key={currentView.view}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek'
            }}
            initialView={currentView.view}
            locale={jaLocale}
            dayCellDidMount={dayCellDidMount}
            dateClick={handleDateClick}
            datesSet={handleDatesSet}
            events={currentView.view === 'timeGridWeek' ? reservations : []}
            eventClick={handleEventClick}
            slotDuration={'01:00:00'}
            slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: false,
                hour12: false
            }}
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }}
            slotMinTime="10:00:00"
            slotMaxTime="20:00:00"
            contentHeight="auto"
            slotLabelInterval="01:00"
            dayMaxEvents={true}
            allDaySlot={false}
            views={{
                timeGridWeek: {
                    slotDuration: '01:00:00',
                    slotLabelInterval: '01:00',
                }
            }}
        />
    );
};

export default ReservationCalendar;
