import React, { useState } from 'react';
import { API_videoENDPOINTS } from '../../config';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atom';
import CategoryButton from './CategoryButton';
import VideoDetail from './VideoDetail';
import '../../css/videoComponent.css';


function CategoryDataDisplay () {
    const user = useRecoilValue(userState);

    const categories =
    [
        "すべて",
        "ファイナンシャルウェルビーイング",
        "ライフプランニング",
        "結婚・出産",
        "老後",
        "家計管理と貯金",
        // "税金/tax",ß
        "住まい",
        "資産運用　基礎編",
        "資産運用　商品編",
        "株式投資"
    ];

    const categoryIds = {
        "すべて": 0,
        "ファイナンシャルウェルビーイング": 1,
        "ライフプランニング": 2,
        "結婚・出産": 3,
        "老後": 4,
        "家計管理と貯金": 5,
        // "税金/tax": 6,
        "住まい": 7,
        "資産運用　基礎編": 8,
        "資産運用　商品編": 9,
        "株式投資": 10
    };

    const [videos, setVideos] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCategoryClick = (categoryName) => {
        const categoryId = categoryIds[categoryName];
        fetchVideosByCategoryId(categoryId);
    };

    const fetchVideosByCategoryId = async (categoryId) => {
        try {
            const response = await fetch(`${API_videoENDPOINTS.category}/${categoryId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            if (!response.ok) {
                if (response.status === 404) {
                    setErrorMessage('動画データはありません');
                } else {
                    throw new Error('API call failed');
                }
            } else {
                const data = await response.json();
                if (categoryId === 0) {
                    const updatedVideos = data.flatMap(category =>
                        category.details.map(detail => ({
                            ...detail,
                            title: category.title
                        }))
                    );
                    setVideos(updatedVideos);
                } else {
                    const updatedVideos = data.details.map(detail => ({
                        ...detail,
                        title: data.title
                    }));
                    setVideos(updatedVideos);
                }
                setErrorMessage('');
            }
        } catch (error) {
            if (error instanceof SyntaxError) {
                console.error('JSON parsing error:', error);
                setErrorMessage('サーバーからのレスポンスが無効なJSON形式です。');
            } else if (error instanceof TypeError) {
                console.error('Network error:', error);
                setErrorMessage('ネットワークエラーが発生しました。');
            } else {
                console.error('Failed to fetch videos:', error);
                setErrorMessage(error.toString());
            }
        }
    };

    return (
        <div>
            <div className='categoryBtnField'>
                {categories.map((categoryName, index) => (
                    <CategoryButton key={index} categoryName={categoryName} onClick={() => handleCategoryClick(categoryName)} />
                ))}
            </div>
        <div className="video-detail-head">
            <div className='showVideoSubtitle'>
                <p>サブタイトル</p>
            </div>
            <div className='showVideoWriter'>
                <p>投稿者</p>
            </div>
            <div className='showVideoTitle'>
                <p>タイトル</p>
            </div>
            <div className='showVideoTag'>
                <p>検索タグ</p>
            </div>
            <div className='showVideoStatus'>
                <p>日時</p>
            </div>
        </div>
        {errorMessage ? (
            <p>{errorMessage}</p>
        ) : (
            videos.map((video,index) => (
                <VideoDetail
                    key={video.id}
                    video={video}
                    title={video.title}
                    className={index % 2 === 0 ? 'even' : 'odd'}
                    />
            ))
        )}
        </div>
    );
}

export default CategoryDataDisplay;