// DeleteButton コンポーネント
import React from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atom';
import { toast } from 'react-toastify';
import { API_videoENDPOINTS } from '../../config';
import { Button } from '@mui/material';

function DeleteButton({ videoId }) {
    const user = useRecoilValue(userState);

    const handleDelete = async () => {
        const apiUrl = `${API_videoENDPOINTS.delete}/${videoId}`;

        fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        })
        .then(response => response.json())
        .then(data => {
            toast.success('ビデオを削除しました');
        })
        .catch(error => {
            toast.error('ビデオを削除に失敗しました');
        });
    };

    return (
        <Button
            onClick={handleDelete}
        >
            ゴミ箱へ移動
        </Button>
    );
}

export default DeleteButton;
