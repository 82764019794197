import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { API_reserveENDPOINTS } from '../config';
import { TextField, Button, Box } from '@mui/material';
import { toast } from 'react-toastify';
import '../css/reservationForm.css';


function ReservationForm() {
  const [user_id, setUser_id] = useState('');
  const [reservation_datetime, setReservation_datetime] = useState('');
  const [reservation_endtime, setReservation_endtime] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [needs, setNeeds] = useState('');
  const user = useRecoilValue(userState);
  const token = user?.token;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reservationData = { user_id, reservation_datetime, reservation_endtime, name, phone, needs };
    setUser_id('');
    setReservation_datetime('');
    setReservation_endtime('');
    setName('');
    setPhone('');
    setNeeds('');

  try {
    const response = await fetch(API_reserveENDPOINTS.store, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(reservationData),
    });

    if (response.ok) {
      toast.success('登録が完了しました');
    } else {
      toast.error('登録に失敗しました');
    }
  } catch (error) {
    toast.error('登録に失敗しました(500)');
  }
};

  return (
    <>
      <form onSubmit={handleSubmit} className='reservationForm'>
        <Box className='timeForm'>
          <TextField
            label="ユーザーID"
            type="text"
            value={user_id}
            onChange={(e) => setUser_id(e.target.value)}
            className='userIdField'
          />
          <TextField
            label="開始日時"
            type="datetime-local"
            value={reservation_datetime}
            onChange={(e) => setReservation_datetime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            className='datetimeField'
          />
          <TextField
            label="終了日時"
            type="datetime-local"
            value={reservation_endtime}
            onChange={(e) => setReservation_endtime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            className='endtimeField'
          />
        </Box>
        <Box className='nameForm'>
          <TextField
            label="名前"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className='nameField'
          />
        </Box>
        <Box className='telForm'>
          <TextField
            label="電話番号"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className='telField'
          />
        </Box>
        <Box className='needsForm'>
          <TextField
            label="その他"
            multiline
            rows={6}
            value={needs}
            onChange={(e) => setNeeds(e.target.value)}
            className='needsField'
          />
        </Box>
        <Box className='btnForm'>
          <Button type="submit" variant="contained" color="primary" className='registerBtn'>
          登録
          </Button>
        </Box>
      </form>
    </>
  );
}

export default ReservationForm;
