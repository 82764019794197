
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atom';
import { SidebarData } from './SidebarData';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import '../../css/sidebar.css';

function Sidebar() {
    const user = useRecoilValue(userState);
    const userRole = user.role;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={isCollapsed ? "sidebar-collapsed" : "sidebar-expanded"}>
            <div className="sidebar">
                <div className="sidebar-toggle-btn" onClick={toggleSidebar}>
                    {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </div>
                <ul className="sidebarList">
                    {SidebarData.filter(item => !item.roles || item.roles.includes(userRole)).map((value, key) => {
                        return (
                            <li
                                key={key}
                                id={window.location.pathname === value.link ? "active" : ""}
                                className="row"
                                onClick={() => {
                                    window.location.pathname = value.link;
                                }}>
                                <div id="icon">{value.icon}</div>
                                <div id="title">{value.title}</div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}


export default Sidebar;
