import React, { useState } from 'react';
import '../css/loginForm.css';
import { TextField, Button, Box, MenuItem } from '@mui/material';
import { API_registerENDPOINTS } from '../config';
import { toast } from 'react-toastify';
import { validateCreateAccount } from '../utils/validation';
import LogoImage from '../images/beyond_money_logo.png';

function Register() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [errors, setErrors] = useState('');
    const [registerError, setRegisterError] = useState('');

    const handleSubmit = async (event) => {
    event.preventDefault();

    const formErrors = validateCreateAccount({ name, email, password, role });
    if(Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }

      // 役職に基づくエンドポイントの決定
    let apiEndpoint = API_registerENDPOINTS[role];

    try {
        const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password }),
        });

        if (response.ok) {
        toast.success('登録が完了しました');
        } else {
        toast.error('登録に失敗しました');
        setRegisterError('このメールアドレスは既に使用されています。');
        }
    } catch (error) {
        toast.error('サーバーエラーが起こりました。');
    }
    };

    return (
        <>
        <Box className='registerPage'>
            <Box className="logoContainer">
                <img src={LogoImage} alt="ロゴ" className="logoImage" />
            </Box>
            <form onSubmit={handleSubmit} className="loginForm">
                <Box className="nameForm">
                    <TextField
                        label="名前"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        className='nameField'
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                </Box>
                <Box className="mailForm">
                    <TextField
                        label="メールアドレス"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        className='mailField'
                    />
                    {errors.email && <div className="error">{errors.email}</div>}
                </Box>
                <Box className="passForm">
                    <TextField
                        label="パスワード"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        className='passField'
                    />
                    {errors.password && <div className="error">{errors.password}</div>}
                </Box>
                <Box className="roleForm">
                    <TextField
                        select
                        label="役職を選択"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        variant="outlined"
                        className='roleField'
                    >
                        <MenuItem value="role1">本社</MenuItem>
                        <MenuItem value="role2">CS</MenuItem>
                        <MenuItem value="role3">ライター</MenuItem>
                        {/* <MenuItem value="role4">カウンセラー</MenuItem> */}
                    </TextField>
                    {errors.role && <div className="error">{errors.role}</div>}
                </Box>
                <Button type="submit" variant="contained" color="primary" className='loginBtn'>
                    新規登録する
                </Button>
                {registerError && <div className="loginError">{registerError}</div>}
            </form>
        </Box>
        </>
    );
}

export default Register;
