
export const validateLoginForm = ({ email, password, role }) => {
    const errors = {};

    if (!email) {
    errors.email = 'メールアドレスを入力してください。';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = '無効なメールアドレスです。';
    }

    if (!password) {
    errors.password = 'パスワードを入力してください。';
    } else if (password.length < 6) {
    errors.password = 'パスワードは6文字以上である必要があります。';
    }

    if (!role) {
    errors.role = '役職を選択してください。';
    }

    return errors;
};

export const validateCreateAccount = ({ name, email, password, role }) => {
    const errors = {};

    if (!name) {
    errors.name = '名前を入力してください。';
    }

    if (!email) {
    errors.email = 'メールアドレスを入力してください。';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = '無効なメールアドレスです。';
    }

    if (!password) {
    errors.password = 'パスワードを入力してください。';
    } else if (password.length < 6) {
    errors.password = 'パスワードは英数6文字以上である必要があります。';
    }
    // 英数のvalidationかける

    if (!role) {
    errors.role = '役職を選択してください。';
    }

    return errors;
};


export const validateCreatePackage = ({ title, tag }) => {
    const errors = {};

    if (!title) {
    errors.title = 'タイトルを選択してください。';
    }

    if (!tag) {
    errors.tag = 'タグを選択してください。';
    }

    // if (!video_photo) {
    // errors.video_photo = '';
    // }

    return errors;
}

// プロフィール作成は全てnull可
export const validateCreateProfile = ({ name, role, profile_detail }) => {
    const errors = {};

    // if (!name) {
    // errors.name = '名前を入力してください。';
    // }

    // if (!profile_photo) {
    // errors.profile_photo = '';
    // }

    // if (!role) {
    //     errors.role = '役職を入力してください。';
    //     }

    // if (!profile_detail) {
    //     errors.profile_detail = 'プロフィールを入力してください。';
    //     }

    return errors;
}

export const validateCreateVideoDetail = ({permalinkSuffix, selectedVideo, movie, subtitle, length, number, article_title, description}) => {
    const errors = {};

    if (!permalinkSuffix) {
    errors.permalinkSuffix = 'linkを設定してください。';
    }

    if (!selectedVideo) {
    errors.selectedVideo = '講座を選択してください。';
    }

    if (!movie) {
    errors.movie = '動画を選択してください。';
    }

    if (!subtitle) {
    errors.subtitle = '＊入力必須項目';
    }

    if (!length) {
    errors.length = '＊入力必須項目';
    } else if (!/^\d+$/.test(length)) {
    errors.length = '数字のみ入力してください。';
    }

    if (!number) {
        errors.number = '＊入力必須項目';
        } else if (!/^\d+$/.test(number)) {
        errors.number = '数字のみ入力してください。';
        }

    if (!article_title) {
        errors.article_title = '記事タイトルを入力してください。';
        }

    if (!description) {
        errors.description = '概要を入力してください。';
        }


    return errors;
}

export const validateCreateSeminar = ({ title, description, seminarPhoto, genre, start_time, end_time, capacity }) => {
    const errors = {};

    if (!title) {
    errors.title = 'タイトルを入力してください。';
    }

    if (!description) {
    errors.description = '概要を入力してください。';
    }

    if (!seminarPhoto) {
    errors.seminarPhoto = '写真を選択してください。';
    }

    if (!genre) {
    errors.genre = 'ジャンルを選択してください。';
    }

    if (!start_time) {
    errors.start_time = '始まりの時間を選択してください。';
    }

    if (!end_time) {
    errors.end_time = '終わりの時間を選択してください。';
    }

    if (!capacity) {
    errors.capacity = '定員数を選択してください。';
    }

    return errors;
}