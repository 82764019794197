
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { userState } from '../atom';
import { API_reserveENDPOINTS } from '../config';
import '../css/calendar.css';
import Schedule from './Schedule';
import UserDetail from './UserDetail';
// import { formatDate } from '../utils/reservationUtils';
import ReservationCalendar from '../components/reserves/ReservationCalendar';


function ManageTop() {
    const user = useRecoilValue(userState);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [reservationsCountByDate, setReservationsCountByDate] = useState({});
    const [weekModalOpen, setWeekModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(getStartOfMonth(new Date()));
    const [endDate, setEndDate] = useState(getEndOfMonth(new Date()));
    const [currentView, setCurrentView] = useState({ view: 'dayGridMonth', date: null });
    const [isLoading, setIsLoading] = useState(true);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const { counselorId } = useParams();


    function getStartOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
    }

    function getEndOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
    }

    useEffect(() => {
        console.log("Current view has been updated to:", currentView);
        if (!startDate || !endDate || !counselorId) return;

        const apiUrl = `${API_reserveENDPOINTS.index}/${counselorId}`;
        let requestBody = JSON.stringify({
            'startDate': startDate,
            'endDate': endDate,
        });

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: requestBody
        })
        .then(response => response.json())
        .then(data => {
            const reservationsData = data.reservations;
            const responseData = data.response;

            if (currentView.view === 'dayGridMonth') {
                const countByDate = {};
                responseData.forEach(reservation => {
                    const date = reservation.reservation_datetime.split(' ')[0];
                    countByDate[date] = (countByDate[date] || 0) + 1;
                });
                setReservationsCountByDate(countByDate);
            } else if (currentView.view === 'timeGridWeek') {
                const events = responseData.map(reservation => {

                    const reservationDateTime = reservation.reservation_datetime;
                    const matchingReservations = reservationsData.filter(data => {
                        return data.reservation_datetime === reservationDateTime;
                    });

                    return {
                        title: '予約',
                        start: reservation.reservation_datetime,
                        end: reservation.reservation_endtime,
                        backgroundColor: 'lightgreen',
                        reservation: matchingReservations,
                    };
                });
                setReservations(events);
            }
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching reservations:', error);
            setIsLoading(false);
        });

    }, [counselorId, startDate, endDate, currentView, user]);

    const handleDatesSet = (dateInfo) => {
        console.log("Current view type:", dateInfo);
        // 表示されている月の年と月を取得
        const monthStart = new Date(dateInfo.startStr);
        const monthEnd = new Date(dateInfo.endStr);

        // 月の開始日と終了日を計算（monthStartの月の1日、monthEndの翌月の0日）
        if (monthStart.getDate() === 1) {
            const startDate = new Date(monthStart.getFullYear(), monthStart.getMonth(), 1);
            const endDate = new Date(monthEnd.getFullYear(), monthEnd.getMonth(), 0);

            console.log("startDate:",startDate);
            console.log("endDate:",endDate);
            // ISO形式の文字列に変換
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            console.log("formattedStartDate:",formattedStartDate);
            console.log("formattedEndDate:",formattedEndDate);

            // 状態を更新
            setCurrentView({ view: dateInfo.view.type, date: formattedStartDate });
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        } else if (monthEnd.getDate() > 27) {
            const startDate = new Date(monthStart.getFullYear(), monthStart.getMonth(), 1);
            const endDate = new Date(monthEnd.getFullYear(), monthEnd.getMonth() + 1, 0);

            console.log("startDate:",startDate);
            console.log("endDate:",endDate);
            // ISO形式の文字列に変換
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            console.log("formattedStartDate:",formattedStartDate);
            console.log("formattedEndDate:",formattedEndDate);

            // 状態を更新
            setCurrentView({ view: dateInfo.view.type, date: formattedStartDate });
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        } else {
            const startDate = new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 1);
            const endDate = new Date(monthEnd.getFullYear(), monthEnd.getMonth(), 0);

            console.log("startDate:",startDate);
            console.log("endDate:",endDate);
            // ISO形式の文字列に変換
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            console.log("formattedStartDate:",formattedStartDate);
            console.log("formattedEndDate:",formattedEndDate);

            // 状態を更新
            setCurrentView({ view: dateInfo.view.type, date: formattedStartDate });
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        }
    };

    const handleDateClick = (arg) => {
        if (arg.view.type === 'dayGridMonth') {
        setSelectedDate(arg.dateStr);
        setModalOpen(true);
        }
    };

    const handleEventClick = (clickInfo) => {

        const clickedStartIso = new Date(clickInfo.event.startStr).toISOString().slice(0, 19);
        const selectedData = reservations.find(reservation => {

            const reservationStartIso = new Date(reservation.start).toISOString().slice(0, 19);
            return reservationStartIso === clickedStartIso;
        });

        setSelectedDate(clickedStartIso);
        setSelectedReservation(selectedData.reservation[0]);
        setWeekModalOpen(true);
    };

    const dayCellDidMount = (arg) => {

        if (arg.view.type === 'dayGridMonth' && !arg.isOtherMonth) {
            console.log("startDate:",startDate);
            console.log("endDate:",endDate);
            const date = arg.date;
            const currentMonth = arg.view.currentStart.getMonth();
            const currentYear = arg.view.currentStart.getFullYear();

            if (date.getMonth() === currentMonth && date.getFullYear() === currentYear) {
                const date = arg.date;
                console.log("Mounted date:", arg.date);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const dateString = `${year}-${month}-${day}`;
                const count = reservationsCountByDate[dateString] || 0;

                let icon;
                if (count > 10) {
                    icon = '×';
                } else if (count > 5) {
                    icon = '△';
                } else if (count > 1) {
                    icon = '○';
                } else {
                    icon = '◎';
                }

                const iconElement = document.createElement('span');
                iconElement.textContent = icon;
                iconElement.className = 'reservation-icon';

                if (icon === '◎') {
                    iconElement.classList.add('icon-circle');
                } else if (icon === '○') {
                    iconElement.classList.add('icon-circle');
                } else if (icon === '△') {
                    iconElement.classList.add('icon-triangle');
                } else if (icon === '×') {
                    iconElement.classList.add('icon-cross');
                }

                arg.el.style.position = 'relative';
                arg.el.appendChild(iconElement);
            }
        }
    };

    return (
        <>
            <div className="calendarForm">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <ReservationCalendar
                    currentView={currentView}
                    reservations={reservations}
                    reservationsCountByDate={reservationsCountByDate}
                    handleDateClick={handleDateClick}
                    handleEventClick={handleEventClick}
                    handleDatesSet={handleDatesSet}
                    dayCellDidMount={dayCellDidMount}
                />
            )}
                {modalOpen && (
                    <Schedule
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}
                        date={selectedDate}
                        counselorId={counselorId}
                    />
                )}
                {weekModalOpen && (
                    <UserDetail
                        isOpen={weekModalOpen}
                        onClose={() => setWeekModalOpen(false)}
                        reservation={selectedReservation}
                    />
                )}
            </div>
        </>
    );
}

export default ManageTop