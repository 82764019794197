
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { API_reserveENDPOINTS } from '../config';
import { Button } from '@mui/material';
import { mapReservationsToTimeSlots } from '../utils/reservationUtils';
import UserDetail from './UserDetail.js';
import '../css/schedule.css';


const defaultSchedules = [...Array(12)].map((_, index) => ({
    time: 10 + index * 1,
    type: ''
}));


function Schedule({ isOpen, onClose, date, counselorId }) {

    const user = useRecoilValue(userState);
    const effectiveCounselorId = counselorId || user.id;
    const [schedules, setSchedules] = useState([]);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);

    const handleReservationClick = (reservation) => {
        if (reservation && reservation.type) {
            setSelectedReservation(reservation);
            setIsDetailsModalOpen(true);
        }
    };

    useEffect(() => {
        const apiUrl = `${API_reserveENDPOINTS.show}/${effectiveCounselorId}/${date}`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
        };

        fetch(apiUrl, { headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                const validData = Array.isArray(data.reservations) ? mapReservationsToTimeSlots(data.reservations) : defaultSchedules;
                setSchedules(validData);
            })
            .catch(error => {
                console.error('Error fetching the schedules:', error);
            });
        }, [effectiveCounselorId, date, user]);

    if (!isOpen) return null;
    return (
        <>
            <div className="modal-backdrop" onClick={onClose}></div>
            <div className="modal">
            <h2>スケジュール: {date}</h2>
                <div className="main_area" id="main_area">
                    <div className="table_outer">
                        <div className="table_area" id="table_area">
                            <table>
                                <thead>
                                <tr className="date_table">
                                    <td>&nbsp;</td>
                                    <td>10:00</td>
                                    <td>11:00</td>
                                    <td>12:00</td>
                                    <td>13:00</td>
                                    <td>14:00</td>
                                    <td>15:00</td>
                                    <td>16:00</td>
                                    <td>17:00</td>
                                    <td>18:00</td>
                                    <td>19:00</td>
                                    <td>20:00</td>
                                    <td>21:00</td>
                                {/* ... */}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                <th className="user-name">
                                    スケジュール
                                    {/* {user ? user.name : 'Loading...'} */}
                                </th>
                                {[...Array(12)].map((_, index) => {
                                    const hour = 10 + index;
                                    const schedule = schedules.find(s => s.time >= hour && s.time < hour + 1);

                                    // 予約がある時間帯に適用するスタイル
                                    const reservedStyle = schedule ? { backgroundColor: 'lightgreen' } : {};

                                    return (
                                        <td
                                        key={index}
                                        style={reservedStyle}
                                        className={schedule && schedule.type ? 'reserved' : 'none'}
                                        onClick={() => handleReservationClick(schedule)}>
                                            {schedule ? `${schedule.type}` : ''}
                                        </td>
                                    );
                                })}
                                        {/* 予約詳細モーダル */}
                                {isDetailsModalOpen && selectedReservation && (
                                    <UserDetail
                                        reservation={selectedReservation}
                                        onClose={() => setIsDetailsModalOpen(false)}
                                    />
                                )}
                                </tr>

                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Button className="scheduleBtn" onClick={onClose}>閉じる</Button>
            </div>
        </>
    );
}


export default Schedule;
