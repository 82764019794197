import React, { useState, useEffect } from 'react';
import { API_seminarENDPOINTS } from '../config';
import { Box, Typography, CircularProgress, Card, CardContent, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import '../css/seminar.css';

function Seminar() {
    const [seminars, setSeminars] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const user = useRecoilValue(userState);
    const navigate = useNavigate();


    useEffect(() => {
        fetch(API_seminarENDPOINTS.index)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setSeminars(data);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching seminars:', error);
            setIsLoading(false);
        });
    }, []);

    const handleEdit = (seminarId) => {
        const seminarToEdit = seminars.find(seminar => seminar.id === seminarId);
        navigate(`/seminar/edit/${seminarId}`, { state: { seminar: seminarToEdit } });
    };

    const handleDelete = (seminarId) => {
        if (window.confirm('本当に削除しますか？')) {
            fetch(`${API_seminarENDPOINTS.delete}/${seminarId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('サーバーエラー');
                }
                return response.json();
            })
            .then(() => {
                toast.success('削除が完了しました');
                setSeminars(seminars.filter(seminar => seminar.id !== seminarId));
            })
            .catch(error => {
                toast.error('削除に失敗しました');
                console.error('Error deleting seminar:', error);
            });
        }
    };

    if (isLoading) {
        return (
            <Box className='loading'>
                <CircularProgress />
            </Box>
        );
    }

    if (seminars.length === 0) {
        return (
            <Box className='seminarTextField'>
                <h2 className='seminarFieldTitle'>セミナー一覧</h2>
                <Box className='seminarTextForm'>
                    <Typography className='seminarText'>セミナー情報はありません。</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box className='seminarIndexField'>
            <h2 className='seminarFieldTitle'>セミナー一覧</h2>
            <Box>
                {seminars.map(({ seminar, remainingSeats }) => (
                    <Grid item key={seminar.id}>
                        <Card className='seminarCard'>
                            <CardContent>
                                <Box className='primaryText'>
                                    <Typography className='seminarTime'>
                                        {seminar.start_time}~
                                    </Typography>
                                    <Box className='seatField'>
                                        <Typography>
                                            定員数:{seminar.capacity}
                                        </Typography>
                                        <Typography>
                                            残席数:{remainingSeats}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className='secondaryText'>
                                    <Typography className='seminarTitle'>
                                        {seminar.title}
                                    </Typography>
                                </Box>
                                <Box className='thirdText'>
                                    <Typography className='seminarDescription'>
                                        {seminar.description}
                                    </Typography>
                                </Box>
                                <Box className='btnText'>
                                    <Button onClick={() => handleEdit(seminar.id)}>編集</Button>
                                    <Button onClick={() => handleDelete(seminar.id)}>削除</Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
}

export default Seminar;
