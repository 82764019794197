
import React from 'react';
import { Button } from '@mui/material';


function TagChangeBtn({ label, onClick }) {
    return (
        <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        className='TagChangeBtn'
        >
        {label}
        </Button>
    );
}

export default TagChangeBtn;
