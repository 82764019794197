

export function mapReservationsToTimeSlots(reservations) {
    return reservations.map(reservation => {
        const datetime = new Date(reservation.reservation_datetime);
        const hour = datetime.getHours() + datetime.getMinutes() / 60;
        const type = "予約済み"; // 実際の予約の種類や状態に応じて調整可能
        return { ...reservation, time: hour, type: type };
    });
}

export function formatDate(dateString) {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Asia/Tokyo'
    });
    return formatter.format(date);
}