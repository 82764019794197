
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_seminarENDPOINTS } from '../config';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import {  useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextField, Button, Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const EditSeminar = () => {
    const { seminarId } = useParams();
    const navigate = useNavigate();
    const user = useRecoilValue(userState);
    const location = useLocation();
    const seminar = location.state?.seminar;

    const [formData, setFormData] = useState({
        title: seminar?.title || '',
        description: seminar?.description || '',
        genre: seminar?.genre || '',
        start_time: seminar?.start_time || '',
        end_time: seminar?.end_time || '',
        capacity: seminar?.capacity || ''
    });

    const genres = [
        { value: 'ファイナンシャルウェルビーイング', label: 'ファイナンシャルウェルビーイング' },
        { value: 'ライフプランニング', label: 'ライフプランニング' },
        { value: '結婚・出産', label: '結婚・出産' },
        { value: '老後', label: '老後' },
        { value: '家計管理と貯金', label: '家計管理と貯金' },
        { value: '税金', label: '税金' },
        { value: '保険について', label: '保険について' },
        { value: '住まい', label: '住まい' },
        { value: '資産運用 基礎編', label: '資産運用 基礎編' },
        { value: '資産運用 商品編', label: '資産運用 商品編' },
        { value: '株式投資', label: '株式投資' },
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // バリデーションとサーバーへの送信処理

        const apiUrl = `${API_seminarENDPOINTS.edit}/${seminarId}`;

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('サーバーエラー');
            }
            return response.json();
        })
        .then(data => {
            toast.success('更新が完了しました');
            navigate('/seminar');
        })
        .catch(error => {
            toast.error('更新に失敗しました');
        });
    };

    return (
        <>
        <Box className='seminarField'>
        <h1>セミナー編集画面</h1>
            <Box component="form" onSubmit={handleSubmit} className='seminarForm'>
                <Box className='titleForm'>
                    <TextField
                        label="タイトル"
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        variant="outlined"
                        className='titleField'
                    />
                </Box>
                <Box className='descriptionForm'>
                    <TextField
                        label="説明"
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        variant="outlined"
                        multiline
                        rows={7}
                        className='descriptionField'
                    />
                </Box>
                <Box className='timeForm'>
                    <Box className='datetimeForm'>
                        <TextField
                            label="開始時間"
                            type="datetime-local"
                            name="start_time"
                            value={formData.start_time}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className='datetimeField'
                        />
                    </Box>
                    <Box className='endtimeForm'>
                        <TextField
                            label="終了時間"
                            type="datetime-local"
                            name="end_time"
                            value={formData.end_time}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className='endtimeField'
                        />
                    </Box>
                </Box>
                <Box className='genreAndNumberForm'>
                    <Box className='genreForm'>
                    <FormControl variant="outlined" className='genreField'>
                            <InputLabel id="genre-label">ジャンル</InputLabel>
                            <Select
                                labelId="genre-label"
                                label="ジャンル"
                                name="genre"
                                value={formData.genre}
                                onChange={handleChange}
                            >
                                {genres.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='numberForm'>
                        <TextField
                            label="定員"
                            type="number"
                            name="capacity"
                            value={formData.capacity}
                            onChange={handleChange}
                            variant="outlined"
                            className='numberField'
                        />
                    </Box>
                </Box>
                <Box className='btnForm'>
                    <Button type="submit" variant="contained" color="primary">
                        予約変更
                    </Button>
                </Box>
            </Box>
        </Box>
        </>
    );
};

export default EditSeminar;
