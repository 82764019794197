
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../atom';
import { API_videoENDPOINTS } from '../../config';

const UseFetchVideoDetail = (videoId) => {
    const [videoDetail, setVideoDetail] = useState(null);

    const user = useRecoilValue(userState);

    useEffect(() => {
        if (!videoId) return;

        const fetchVideoDetail = async () => {
            try {
                const response = await fetch(`${API_videoENDPOINTS.indexDetail}/${videoId}`, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('ビデオデータの取得に失敗しました');
                }
                const data = await response.json();
                setVideoDetail(data);
            } catch (error) {
                console.error("error:",error);
            }
        };

        fetchVideoDetail();
    }, [videoId, user.token]);

    return { videoDetail };
};

export default UseFetchVideoDetail;