import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../atom';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoImage from '../../images/beyond_money_logo_non.png';
import '../../css/header.css';

function Header() {
    const user = useRecoilValue(userState);
    const setUser = useSetRecoilState(userState);
    const navigate = useNavigate();

    // ログアウト関数
    const handleLogout = () => {
        // 確認ダイアログを表示
        const confirmLogout = window.confirm('ログアウトしてもよろしいですか？');
        if (confirmLogout) {
            setUser(null);
            navigate('/');
        }
    };

    return (
        <AppBar position="fixed">
            <Toolbar>
                {/* ロゴスペース */}
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <img src={LogoImage} alt="ロゴ" className="header-logo" />
                    <Typography className="header-logo-name">
                        beyond money
                    </Typography>
                </Box>

                {/* ユーザー名とログアウトボタン */}
                <Box className="header-user">
                    <Typography className="header-user-name">
                        {user ? user.name : 'Loading...'}
                    </Typography>
                    <Button color="inherit" onClick={handleLogout}>ログアウト</Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
