
import { useState, useEffect } from 'react';
import { API_videoENDPOINTS } from '../../config';
import { toast } from 'react-toastify';

export const UseFetchVideos = (userToken) => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(API_videoENDPOINTS.index, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('ビデオの取得に失敗しました');
                }

                const data = await response.json();
                setVideos(data);
            } catch (error) {
                toast.error('ビデオの取得に失敗しました');
                console.error(error);
            }
        };

        if (userToken) {
            fetchVideos();
        }
    }, [userToken]);

    return { videos };
};
