
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_userENDPOINTS } from '../config';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { Typography, Box, CardMedia } from '@mui/material';
import { Phone, Person, Info, AssignmentInd } from '@mui/icons-material';
import '../css/userDetail.css';

const ShowUser = () => {
    const [users, setUsers] = useState(null);
    const [, setIsLoading] = useState(true);
    const { userId } = useParams();
    const user = useRecoilValue(userState);
    const phoneNumber = users?.phone || '登録がありません';

    useEffect(() => {
        const apiUrl = `${API_userENDPOINTS.index}/${userId}`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
        };

        fetch(apiUrl, { headers })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setUsers(data);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching seminars:', error);
            setIsLoading(false);
        });
    }, [userId, user]);

    if (!users) {
        return <div>Loading...</div>;
    }

    return (
        <div className='usersField'>
            <Box className='userField'>
                <Box className='nameAndPhotoField'>
                    <Box>
                        <Typography variant="body1" className="nameInfo">
                            <Person />   {users.user_name}
                        </Typography>
                    </Box>
                    <Box className='photoField'>
                    {users.profile_photo ? (
                        <CardMedia
                            className="profile-photo"
                            image={users.profile_photo}
                            title="プロフィール写真"
                        />
                    ) : (
                        <div>プロフィール写真がありません</div>
                    )}
                    </Box>
                </Box>
                <Box className='phoneAndBtnField'>
                    <Typography variant="body1" className="phoneInfo">
                        <Phone />
                        <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {phoneNumber}
                        </a>
                    </Typography>
                </Box>
            </Box>

            <Box className="mainField">
                {/* 予約情報 */}
                {/* <Box className="counselorField">
                    <Typography variant="body1" className="counselorInfo">
                        <Person /> カウンセラー名: {reservation.counselor.name}
                    </Typography>
                    <Typography variant="body1" className="emailInfo">
                        <EmailIcon />  {reservation.counselor.email}
                    </Typography>
                    <Typography variant="body1" className="dateInfo">
                        <AccessTime /> 予約開始日: {formatDate(reservation.reservation_datetime)}
                    </Typography>
                    <Typography variant="body1" className="dateTimeInfo">
                        <AccessTime /> 開始時間: {formatTime(reservation.reservation_datetime)}
                    </Typography>
                    <Typography variant="body1" className="endTimeInfo">
                        <AccessTime /> 終了時間: {formatTime(reservation.reservation_endtime)}
                    </Typography>
                </Box> */}
                <Box className="userProfileField">
                        <Typography variant="body1" className="roleInfo">
                            <AssignmentInd />役職： {users.role}
                        </Typography>
                        <Typography variant="body1" className="info">
                            <Info /> {users.profile_detail}
                        </Typography>
                </Box>
                <Box className="questionnaireField">
                    <Typography variant="body1" className="info">
                        <Info /> アンケート情報:
                    </Typography>
                    <Typography variant="body1" className="ageInfo">
                        年齢: {users.questionnaire?.[0]?.age ? users.questionnaire[0].age + '歳' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="genderInfo">
                        性別: {users.questionnaire?.[0]?.gender.選択肢 ? users.questionnaire[0].gender.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="prefectureInfo">
                        出身: {users.questionnaire?.[0]?.prefecture.都道府県 ? users.questionnaire[0].prefecture.都道府県 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="jobInfo">
                        職業: {users.questionnaire[0]?.job.職業 ? users.questionnaire[0]?.job.職業 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        退職予定歳: {users.questionnaire?.[0]?.work_until_age ? users.questionnaire[0].work_until_age + '歳' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        年収: {users.questionnaire?.[0]?.income ? users.questionnaire[0].income + '万円' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        貯金: {users.questionnaire?.[0]?.savings ? users.questionnaire[0].savings + '万円' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        支出: {users.questionnaire?.[0]?.expenses ? users.questionnaire[0].expenses + '万円' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        扶養の有無: {users.questionnaire?.[0]?.has_spouse.選択肢 ? users.questionnaire[0].has_spouse.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        子供の有無: {users.questionnaire?.[0]?.has_child.選択肢 ? users.questionnaire[0].has_child.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        子供の人数: {users.questionnaire?.[0]?.children_count ? users.questionnaire[0].children_count + '人' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        子供の年齢: {users.questionnaire?.[0]?.children_age ? users.questionnaire[0].children_age + '歳' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        将来欲しい子供の人数: {users.questionnaire?.[0]?.future_child.選択肢 ? users.questionnaire[0].future_child.選択肢 + '人' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        子供の学校: {users.questionnaire?.[0]?.university.選択肢 ? users.questionnaire[0].university.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        大学の通い方: {users.questionnaire?.[0]?.home.選択肢 ? users.questionnaire[0].home.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        現在の住まい: {users.questionnaire?.[0]?.current_situation.選択肢 ? users.questionnaire[0].current_situation.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        マイホーム購入: {users.questionnaire?.[0]?.buy_home.選択肢 ? users.questionnaire[0].buy_home.選択肢 : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        購入予定時期: {users.questionnaire?.[0]?.mst_myhome ? users.questionnaire[0].mst_myhome + '年後' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        現在の家賃: {users.questionnaire?.[0]?.rent ? users.questionnaire[0].rent + '万円' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        退職金: {users.questionnaire?.[0]?.retirement_fund ? users.questionnaire[0].retirement_fund + '万円' : '未回答'}
                    </Typography>
                    <Typography variant="body1" className="incomeInfo">
                        車購入: {users.questionnaire?.[0]?.buy_car.選択 ? users.questionnaire[0].buy_car.選択 + '' : '未回答'}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default ShowUser;
