
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'recoilPersist', // ローカルストレージに保存されるキー
  storage: localStorage, // 使用するストレージの種類
});

export const userState = atom({
  key: 'userState',
  default: null, // 初期状態
  effects_UNSTABLE: [persistAtom], // 永続化の設定を追加
});