import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { userState } from '../atom';
import '../css/loginForm.css';
import { API_loginENDPOINTS } from '../config';
import LogoImage from '../images/beyond_money_logo.png';
import { validateLoginForm } from '../utils/validation';

function LoginForm() {
    const [, setUser] = useRecoilState(userState);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formErrors = validateLoginForm({ email, password, role });
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors); // エラーがあれば状態を更新
            return; // エラーがある場合はここで処理を中断
        }

        let apiEndpoint = API_loginENDPOINTS[role];

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const responseData = await response.json();
                const { token, name, id } = responseData;
                localStorage.setItem('token', token);
                localStorage.setItem('role', role);
                setUser({ token, name, role, id });
                toast.success('ログイン成功しました');
                navigate('/home');
            } else {
                toast.error('ログインに失敗しました');
                console.log("response:",response);
                setLoginError('メールアドレスあるいはパスワードが違います。');
            }
        } catch (error) {
            toast.error('サーバーエラーが発生しました');
            console.error("error:",error);
        }
    };

    return (
        <>
        <Box className='loginPage'>
            <Box className="logoContainer">
                <img src={LogoImage} alt="ロゴ" className="logoImage" />
            </Box>
            <form onSubmit={handleSubmit}>
                <Box className="mailForm">
                    <TextField
                        label="メールアドレス"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        className='mailField'
                    />
                    {errors.email && <div className="error">{errors.email}</div>}
                </Box>
                <Box className="passForm">
                    <TextField
                        label="パスワード"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        className='passField'
                    />
                    {errors.password && <div className="error">{errors.password}</div>}
                </Box>
                <Box className="roleForm">
                    <TextField
                        select
                        label="役職を選択"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        variant="outlined"
                        className='roleField'
                    >
                        <MenuItem value="role1">本社</MenuItem>
                        <MenuItem value="role2">CS</MenuItem>
                        <MenuItem value="role3">ライター</MenuItem>
                        {/* <MenuItem value="role4">カウンセラー</MenuItem> */}
                    </TextField>
                    {errors.role && <div className="error">{errors.role}</div>}
                </Box>
                <Button type="submit" variant="contained" color="primary" className='loginBtn'>
                    ログイン
                </Button>
                {loginError && <div className="loginError">{loginError}</div>}
            </form>
        </Box>
        </>
    );
}

export default LoginForm;