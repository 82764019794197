import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import { toast } from 'react-toastify';
import { API_writerENDPOINTS } from '../config';
import { API_counselorENDPOINTS } from '../config';
import { TextField, Button, Box } from '@mui/material';
import { validateCreateProfile } from '../utils/validation';
import '../css/profileCreate.css';

function CreateProfile() {
    const user = useRecoilValue(userState);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [profileDetail, setProfileDetail] = useState('');
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({});


    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setProfilePhoto(file);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateCreateProfile({ name, role, profileDetail });
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const form = new FormData();

        form.append('name', name);
        form.append('profile_photo', profilePhoto);
        form.append('role', role);
        form.append('profile_detail', profileDetail);

        let apiEndpoint;
        if (user.role === 'role3') {
            apiEndpoint = API_writerENDPOINTS.store; // writer APIエンドポイント
        } else if (user.role === 'role4') {
            apiEndpoint = API_counselorENDPOINTS.store; // counselor APIエンドポイント
        } else {
            return;
        }

        try {
          // APIリクエストの送信
            const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
            body: form,
            });

          // レスポンスの処理
            if (response.ok) {
            toast.success('登録が完了しました');
            } else {
            toast.error('登録に失敗しました');
            }
        } catch (error) {
            toast.error('バリデーションエラーが起こりました。');
        }
    }

    return (
        <form onSubmit={handleSubmit} className='profileField'>
            <Box className='userNameForm'>
                <TextField
                    label="名前"
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='userNameField'
                />
                {errors.name && <div className="error">{errors.name}</div>}
            </Box>
            <Box className='userPhotoForm'>
                <Button
                    variant="contained"
                    component="label"
                    className='profilePhotoField'
                >
                    プロフィール写真
                    <input type="file" hidden onChange={handleImageChange} />
                </Button>
                {profilePhoto && (
                    <>
                        <p className='file-url'>選択された画像: {profilePhoto.name}</p>
                        <Button
                            onClick={toggleModal}
                        >
                            プレビュー
                        </Button>
                    </>
                )}
                {isModalOpen && (
                    <div className='modalPreview'>
                        <img src={URL.createObjectURL(profilePhoto)} alt="プレビュー" className='modalPreviewImg' />
                            <Button
                                onClick={toggleModal}
                            >
                                閉じる
                            </Button>
                    </div>
                )}
            </Box>
            <Box className='userRoleForm'>
                <TextField
                    label="役割"
                    type="text"
                    id="role"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className='userRoleField'
                />
                {errors.role && <div className="error">{errors.role}</div>}
            </Box>
            <Box className='userProfileDetailForm'>
                <TextField
                    label="プロフィール詳細"
                    id="profileDetail"
                    name="profileDetail"
                    value={profileDetail}
                    onChange={(e) => setProfileDetail(e.target.value)}
                    multiline
                    rows={10}
                    className='userProfileDetailField'
                />
                {errors.profileDetail && <div className="error">{errors.profileDetail}</div>}
            </Box>
            <Box>
                <Button className='createProfileBtn' variant="contained" type="submit">登録</Button>
            </Box>

        </form>
    );
}

export default CreateProfile;
