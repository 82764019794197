
import React from 'react';
import Modal from 'react-modal';
import { Button } from '@mui/material';
import '../css/preview.css';
import MovieImage from '../images/default/movie_img01.png';
import SamuneImage from '../images/default/movie_thumnails01.png';

Modal.setAppElement('#root');

function Preview({ isOpen, handleClose, formData }) {
    // console.log(formData);

    const createMarkup = (content) => {
        return { __html: content.replace(/\n/g, '<br>') };
    };

    const customStyles = {
        content: {
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '700px',
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    return (
        <div id="root">
            <Modal
                appElement={document.getElementById('root') || undefined}
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                ariaHideApp={false}
            >
                <div>
                    <div id="breadList">
                        <div className="inner">
                            <ul>
                                <li><p>トップページ</p></li>
                                <li><span>第1章 税金の基礎知識 ～生涯賃金と生涯税金～</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="innerL">
                        <section className="movieDetailWrap">
                            <div className="detail">
                                <div className="movie">
                                    {
                                        formData.movie
                                            ? <video src={formData.movie.previewUrl} alt="" />
                                            : <img src={MovieImage} alt="" />
                                    }
                                </div>
                                <div className="about">
                                    {
                                        formData.subtitle
                                            ? <h1 dangerouslySetInnerHTML={createMarkup(formData.subtitle)} />
                                            : <h1>第1章 第1章 ファイナンシャル・ウェルビーイングとは</h1>
                                    }
                                    {
                                        formData.content
                                            ? <p dangerouslySetInnerHTML={createMarkup(formData.content)} />
                                            : <p>ファイナンシャル・ウェルビーイングとは、経済的な幸福を指す要素です。収入は多いに越したことはないですが、人はお金を使うことにも幸福を感じます。自分の価値観の中で本当に必要な物や経験にしっかりとお金を使うことができ、将来の経済的安心を実感できるように資産を管理・運用できている。そんなファイナンシャル・ウェルビーイングを本動画では学ぶことができます</p>
                                    }
                                    <div className="tags">
                                        <div className="good"><span>310</span>いいね</div>
                                        <div className="bookmark"><span>310</span>保存</div>
                                    </div>
                                </div>
                            </div>
                            <div className="all">
                                <div className="conts">
                                    <div className="title">
                                        <div className="icon"><img src="" alt="" className="icon" /></div>
                                        <span>講座内一覧</span>
                                    </div>
                                    <div className="scroll">
                                        <div className="courseList">
                                            <div className="courseCardSmall Vertical">
                                                <div className="left">
                                                    <div className="img"><img src={formData.videodetailPhoto ? formData.videodetailPhoto : SamuneImage} alt="" /></div>
                                                    <div className="status active"><span>受講中</span></div>
                                                </div>
                                                <div className="right">
                                                    <div className="content">
                                                        <div className="title">
                                                        {
                                                            formData.subtitle
                                                                ? <h1 dangerouslySetInnerHTML={createMarkup(formData.subtitle)} />
                                                                : <h1>第1章 第1章 ファイナンシャル・ウェルビーイングとは</h1>
                                                        }
                                                        </div>
                                                        <div>
                                                            <div className="time"><span>視聴時間 <span className="oms">1:33:38</span></span></div>
                                                            <div className="info">
                                                                <div className="tags"><span className="movie">動画</span><span className="article">記事</span></div>
                                                                <div className="good"><span className="number">310</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="courseCardSmall Vertical">
                                                <div className="left">
                                                    <div className="img attended"><img src={formData.videodetailPhoto ? formData.videodetailPhoto : SamuneImage} alt="" /></div>
                                                    <div className="status attended"><span>受講済み</span></div>
                                                </div>
                                                <div className="right">
                                                    <div className="content">
                                                        <div className="title">
                                                        {
                                                            formData.subtitle
                                                                ? <h1 dangerouslySetInnerHTML={createMarkup(formData.subtitle)} />
                                                                : <h1>第1章 第1章 ファイナンシャル・ウェルビーイングとは</h1>
                                                        }
                                                        </div>
                                                        <div>
                                                            <div className="time"><span>視聴時間 <span className="oms">1:33:38</span></span></div>
                                                            <div className="info">
                                                                <div className="tags"><span className="movie">動画</span><span className="article">記事</span></div>
                                                                <div className="good"><span className="number">310</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="courseCardSmall Vertical">
                                                <div className="left">
                                                    <div className="img"><img src={formData.videodetailPhoto ? formData.videodetailPhoto : SamuneImage} alt="" /></div>
                                                    <div className="status"><span>未受講</span></div>
                                                </div>
                                                <div className="right">
                                                    <div className="content">
                                                        <div className="title">
                                                        {
                                                            formData.subtitle
                                                                ? <h1 dangerouslySetInnerHTML={createMarkup(formData.subtitle)} />
                                                                : <h1>第1章 第1章 ファイナンシャル・ウェルビーイングとは</h1>
                                                        }
                                                        </div>
                                                        <div>
                                                            <div className="time"><span>視聴時間 <span className="oms">1:33:38</span></span></div>
                                                            <div className="info">
                                                                <div className="tags"><span className="movie">動画</span><span className="article">記事</span></div>
                                                                <div className="good"><span className="number">310</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="courseCardSmall Vertical">
                                                <div className="left">
                                                    <div className="img"><img src={formData.videodetailPhoto ? formData.videodetailPhoto : SamuneImage} alt="" /></div>
                                                    <div className="status"><span>未受講</span></div>
                                                </div>
                                                <div className="right">
                                                    <div className="content">
                                                        <div className="title">
                                                        {
                                                            formData.subtitle
                                                                ? <h1 dangerouslySetInnerHTML={createMarkup(formData.subtitle)} />
                                                                : <h1>第1章 第1章 ファイナンシャル・ウェルビーイングとは</h1>
                                                        }
                                                        </div>
                                                        <div>
                                                            <div className="time"><span>視聴時間 <span className="oms">1:33:38</span></span></div>
                                                            <div className="info">
                                                                <div className="tags"><span className="movie">動画</span><span className="article">記事</span></div>
                                                                <div className="good"><span className="number">310</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="courseCardSmall Vertical">
                                                <div className="left">
                                                    <div className="img"><img src={formData.videodetailPhoto ? formData.videodetailPhoto : SamuneImage} alt="" /></div>
                                                    <div className="status"><span>未受講</span></div>
                                                </div>
                                                <div className="right">
                                                    <div className="content">
                                                        <div className="title">
                                                        {
                                                            formData.subtitle
                                                                ? <h1 dangerouslySetInnerHTML={createMarkup(formData.subtitle)} />
                                                                : <h1>第1章 第1章 ファイナンシャル・ウェルビーイングとは</h1>
                                                        }
                                                        </div>
                                                        <div>
                                                            <div className="time"><span>視聴時間 <span className="oms">1:33:38</span></span></div>
                                                            <div className="info">
                                                                <div className="tags"><span className="movie">動画</span><span className="article">記事</span></div>
                                                                <div className="good"><span className="number">310</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="bgWhite">
                        <div className="innerL">
                            <section className="movieLearn">
                                <div className="article">
                                    <div className="in">
                                        <div className="contsTitle">この講座を記事で学ぶ</div>
                                        {
                                            formData.article_title
                                                ? <div dangerouslySetInnerHTML={createMarkup(formData.article_title)} />
                                                : <h2>税金の基礎知識 〜生涯賃金と生涯税金〜</h2>
                                        }
                                        <div className="intro">
                                        {
                                            formData.description
                                                ? <p dangerouslySetInnerHTML={createMarkup(formData.description)} />
                                                : <p>日本で生きていく上で、知らなければならない税金について、全4回にわたって学んでいきましょう。 第1回は、生涯賃金と生涯税金についてです。</p>
                                        }
                                        </div>
                                        <div className="point">
                                            <ul>
                                            {
                                                formData.points[0] ?
                                                formData.points.map((item, index) => (
                                                    <li key={index} dangerouslySetInnerHTML={createMarkup(item)}></li>
                                                ))
                                            :
                                                <>
                                                    <li>平均的な大卒男性で、生涯に1億2,000万円もの税金を払っている。</li>
                                                    <li>今後、ますます増税予定である。</li>
                                                    <li>節税は国民の権利。勉強して合法的に節税することが大切。</li>
                                                </>
                                            }
                                            </ul>
                                        </div>
                                        <div className="index">
                                            <div className="title">目次</div>
                                            <ol>
                                                {
                                                    formData.articleContent[0] ?
                                                    formData.articleContent.map((item, index) => (
                                                    <li key={index} dangerouslySetInnerHTML={createMarkup(item.subtitle)}></li>
                                                    ))
                                                :
                                                    <>
                                                    <li>平均的な大卒男性で、生涯に1億2,000万円もの税金を払っている。</li>
                                                    <li>今後、ますます増税予定である。</li>
                                                    <li>節税は国民の権利。勉強して合法的に節税することが大切。</li>
                                                    <li><p>04 脱税は論外！</p></li>
                                                    <li><p>05 マネーリテラシーを高めよう！</p></li>
                                                    </>
                                                }
                                            </ol>
                                        </div>
                                        {
                                            formData.articleContent[0] ?
                                            formData.articleContent.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <h3 id={String(index).padStart(2, '0')} dangerouslySetInnerHTML={createMarkup(`${String(index + 1).padStart(2, '0')} ${item.subtitle}`)}></h3>
                                                <p><img src={item.articlePhotoPreviewUrl} alt="" /></p>
                                                <p dangerouslySetInnerHTML={createMarkup(`${item.content}`)}></p>
                                            </React.Fragment>
                                            ))
                                            :
                                            <>
                                                <h3 id="01">01 「生涯賃金」ってどれくらい？</h3>
                                                <p>「生涯賃金」とは、皆さんが一生のうちに稼ぐ金額の合計です。大卒男性で2億6,000万円、大卒女性で2億1,000万円といわれ（2021年調査）、皆さんの親世代では「生涯賃金3億円」といわれていたころと比べると、ずいぶん下がっていることがわかります。</p>
                                                <p>そして「生涯税金」とは、皆さんが生まれてから死ぬまでに払う税金の総額です。毎月の給料から天引きされている税金は「所得税」「住民税」「社会保険料」。“保険料” と付きますが、社会保険料とは実質的には税金です。これらが天引きされ、給料が支払われています。その他、消費税、相続税、贈与税、固定資産税……お酒やたばこを購入する人は、それらにも税金が発生します。</p>
                                                <h3 id="02">平均的男性が1年間に払う税額は？</h3>
                                                <p><img src={SamuneImage} alt="" /></p>
                                                <p>例えば「年収500万円・30歳・独身男性」の場合、年間で社会保険料が72万円、所得税が14万円、住民税が24万円。合計110万円の税金が引かれ、手取りは390万円になります。また、500万円の給料を労働者に支払うために、実は会社は同額の社会保険料（72万円）を納税する必要があります。さらにいうと、例えば手取り390万円のうち220万円を生活費に使う場合、20万円の消費税を支払っています。<br />
                                                つまり、年収500万円の人が1年間普通に過ごすと、合計202万円も納税することになります。ご自身の現在の年収や、将来目指す年収の金額の数字を確認し、頭の片隅に入れておいてください。</p>
                                                <p>年収900万円の人が年収1,000万円に上がった場合は、手取りが662万円から728万円となり、66万円しか増えていないことになります。これが、サラリーマンが出世しても、思ったよりお金が残らないことになる原因です。実はかなりの額の税金を、気付かないうちに納めているのです。</p>
                                                <h3 id="03">止まらない増税ラッシュ</h3>
                                                <p><img src={SamuneImage} alt="" /></p>
                                                <p>最近のニュースでは、「通勤手当」への課税や「退職金」への増税が検討されたり（所得税・住民税の増加）、「社会保険料」の値上げが毎年のように行われたり（社会保険料の増加）、「消費税」の増税までもが検討されています。また、令和6年から「森林環境税」という新しい税金がスタートし、住民税が増えることも確定しています。サラリーマンの負担増の増税ラッシュは、とどまるところを知りません。こんな大増税時代に、いくら一生懸命残業しても、出世競争を戦っても、体や家庭を犠牲にして会社に尽くしても、税金とうまく付き合わなければ、実生活が豊かになることはありえません。</p>
                                                <h3 id="04">脱税は論外！</h3>
                                                <p>こんな話ばかり聞くと、なんとかして税金を払わずに済む方法がないかと考えたくなりますよね？ ここで気を付けなければいけないのは、脱税をすると、とてつもない追徴課税が来るということです。脱税が税務署に知られると、具体的には以下の3つの加算税がかかり、下に行くほど厳しくなります。<br />　過少申告加算税……悪意はないが、実際の所得よりも少なく申告した場合。加算額は10〜15%。<br />　無申告加算税……所得があったにも関わらず、申告をしなかった場合にかかる加算税。加算額は15〜20％。<br />　重加算税……脱税に対して悪質性が認められる場合に付く。加算額は35〜40%。<br />これらの加算に加え、年利14.6％の延滞税も付き、非常に厳しい措置となります。例えば、2019年にチュートリアルの徳井さんが脱税を指摘された件では、1億円以上の追徴課税があったといわれています。青汁王子こと三崎優太さんのケースでは、追徴課税額は公表されていないものの、追徴課税に加え、懲役2年、執行猶予4年、4,600万円の罰金と、刑事罰まで受けています。</p>
                                                <h3 id="05">マネーリテラシーを高めよう！</h3>
                                                <p><img src={SamuneImage} alt="" /></p>
                                                <p>学校も上司も会社も親も、誰も税金について教えてくれません。ただ、何もしなければ、とんでもない金額の税金を気付かぬ間に支払うことになります。間違った節税を行えば、逮捕されるリスクすらあります。正しい税金の仕組みを理解し、合法的に納税額を抑えていく方法を、皆さんにお伝えできればと思います。社会や環境をすぐに大きく変えることは難しいですが、自分が変わることは案外簡単です。納税は国民の義務ですが、節税は当然の国民の権利なのです。「給料をあげるために努力する」ことが無駄とはいいません。ただ、それと同じくらい「マネーリテラシーを高めて、正しく税金を減らす」こと、「税金が天引きされない、給料以外の所得を増やすこと」を大切にしてください。それが、どんな社会になってもお金に困らないための、重要な能力になります。</p>
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="innerL">
                        <div className="comment">
                            <div className="in">
                                <h3 className="title">この講座のコメント</h3>
                                <div className="conts">
                                    動画への感想や分からなかったこと、疑問に思ったことなどアウトプットしてみましょう。
                                    <div className="write">
                                        <div className="caption">
                                            <img src="" alt="" className="icon" />
                                            <span>この講座へのコメントを書く</span>
                                        </div>
                                        <form action="#" method="post">
                                            <div className="textarea">
                                                <textarea name="comment" cols="30" rows="5" className="commentarea"></textarea>
                                                <span className="nowCntWrap"><span className="nowCnt">0</span>/400文字</span>
                                            </div>
                                            <p className="submit"><input type="submit" value="送信する" className="submitBtn" /></p>
                                        </form>
                                    </div>
                                    <div className="list first">
                                        <div className="profile">
                                            <img src="" alt="" className="icon" />
                                            <div className="txt">
                                                <div><span className="name">江頭 浩</span><span className="member">一般会員</span></div>
                                                <div>朝日新聞デジタル / 教授</div>
                                            </div>
                                            <div className="dotWrap">
                                                <span className="dot"></span>
                                                <div className="deletWrap" id="modal-open"><img src="" alt="" className="icon" />このコメントを削除する</div>
                                            </div>
                                            <div id="modal-main">
                                                <span className="closeBtn">×</span>
                                                <div className="deleteTitle">下記のコメントを削除しますか？</div>
                                                <div className="profile">
                                                    <img src="" alt="" className="icon" />
                                                    <div className="txt">
                                                        <div><span className="name">江頭 浩</span><span className="member">一般会員</span></div>
                                                        <div>朝日新聞デジタル / 教授</div>
                                                    </div>
                                                </div>
                                                <div className="time">2時間前</div>
                                                <div className="area">
                                                    東京電力ホールディングス（HD）は11日、福島第1原子力発電所の処理水の海洋放出について、初回の放出を同日正午過ぎに終了したと発表した。中国による日本産水産物の全面禁輸を受け、政府は水産業者の支援を急ぐ。<br />東電は8月24日に処理水の放出を始め、1日当たりおよそ460トン、計7800トンほどを流した。放出後は毎日、原発周辺で海水に含まれる放射性物質のトリチウム濃東京電力ホールディングス（HD）は11日、福島第1原子力発電所の処理水の海洋放出について、初回の放出を同日正午過ぎに終了したと発表した。中国による日本産水産物の全面禁輸を受け、政府は水産業者の支援を急ぐ。<br />東電は8月24日に処理水の放出を始め、1日当たりおよそ460トン、計7800トンほどを流した。放出後は毎日、原発周辺で海水に含まれる放射性物質のトリチウム濃
                                                </div>
                                                <div className="deleteBtn">削除する</div>
                                            </div>
                                        </div>
                                        <div className="time">2時間前</div>
                                        <div className="area">
                                            <p>
                                            東京電力ホールディングス（HD）は11日、福島第1原子力発電所の処理水の海洋放出について、初回の放出を同日正午過ぎに終了したと発表した。中国による日本産水産物の全面禁輸を受け、政府は水産業者の支援を急ぐ。<br />東電は8月24日に処理水の放出を始め、1日当たりおよそ460トン、計7800トンほどを流した。放出後は毎日、原発周辺で海水に含まれる放射性物質のトリチウム濃東京電力ホールディングス（HD）は11日、福島第1原子力発電所の処理水の海洋放出について、初回の放出を同日正午過ぎに終了したと発表した。中国による日本産水産物の全面禁輸を受け、政府は水産業者の支援を急ぐ。<br />東電は8月24日に処理水の放出を始め、1日当たりおよそ460トン、計7800トンほどを流した。放出後は毎日、原発周辺で海水に含まれる放射性物質のトリチウム濃
                                            </p>
                                        </div>
                                    </div>
                                    <div className="moreBtnConts"><div className="moreBtn">コメントをもっと見る</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button onClick={handleClose}>閉じる</Button>
            </Modal>
        </div>
    );
}

export default Preview;
