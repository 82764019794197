import React from 'react';
import { Header, Sidebar } from './components/layouts';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import 'react-toastify/dist/ReactToastify.css';
import LoginForm from './auth/LoginForm';
import Register from './auth/Register';
import CreateProfile from './user/CreateProfile';
import UserSearchForm from './user/UserSearchForm';
import ShowUser from './user/ShowUser';
import PackageForm from './video/PackageForm';
import MovieForm from './video/MovieForm';
import MovieFormByMaster from './video/MovieFormByMaster';
import IndexVideo from './video/IndexVideo';
import EditVideo from './video/EditVideo';
import Preview from './video/Preview';
import Top from './reserve/top';
import ManageTop from './reserve/ManageTop';
import SearchForm from './reserve/SearchForm';
import Seminar from './reserve/Seminar';
import SeminarForm from './reserve/SeminarForm';
import EditSeminar from './reserve/EditSeminar';
import ReservationForm from './reserve/ReservationForm';
import ManageReservationForm from './reserve/ManageReservationForm';
import Oneday from './reserve/Oneday';
import Home from './Home';
import './css/App.css';





const theme = createTheme({
  palette: {
    primary: {
      main: '#3d5d80',   // #headerの色
    },
  },
});


function App() {
  return (
    <>
    <RecoilRoot>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/home" element={<LayoutWithHeaderFooterAndSidebar><Home /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/register" element={<LayoutWithHeaderFooterAndSidebar><Register /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/registerProfile" element={<LayoutWithHeaderFooterAndSidebar><CreateProfile /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/users" element={<LayoutWithHeaderFooterAndSidebar><UserSearchForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/user/show/:userId" element={<LayoutWithHeaderFooterAndSidebar><ShowUser /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/reserve" element={<LayoutWithHeaderFooterAndSidebar><Top /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/calendar/:counselorId" element={<LayoutWithHeaderFooterAndSidebar><ManageTop /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/reserve-manage" element={<LayoutWithHeaderFooterAndSidebar><SearchForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/store-reservation" element={<LayoutWithHeaderFooterAndSidebar><ReservationForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/store-reservation/:counselorId" element={<LayoutWithHeaderFooterAndSidebar><ManageReservationForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/store-reservation-manage" element={<LayoutWithHeaderFooterAndSidebar><SearchForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/oneday-reservation" element={<LayoutWithHeaderFooterAndSidebar><Oneday /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/seminar" element={<LayoutWithHeaderFooterAndSidebar><Seminar /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/seminar-store" element={<LayoutWithHeaderFooterAndSidebar><SeminarForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/seminar/edit/:seminarId" element={<LayoutWithHeaderFooterAndSidebar><EditSeminar /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/post-package" element={<LayoutWithHeaderFooterAndSidebar><PackageForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/post-movie" element={<LayoutWithHeaderFooterAndSidebar><MovieForm /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/post-video/master/:writerId" element={<LayoutWithHeaderFooterAndSidebar><MovieFormByMaster /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/preview" element={<Preview />} />
          <Route path="/index-video" element={<LayoutWithHeaderFooterAndSidebar><IndexVideo /></LayoutWithHeaderFooterAndSidebar>} />
          <Route path="/edit/:videoDetailId" element={<LayoutWithHeaderFooterAndSidebar><EditVideo /></LayoutWithHeaderFooterAndSidebar>} />
          {/* <Route path="/searchVideo/:writerId" element={<LayoutWithHeaderFooterAndSidebar><SearchVideo /></LayoutWithHeaderFooterAndSidebar>} /> */}
        </Routes>
      </Router>
    </RecoilRoot>
    </>
  );
}

function LayoutWithHeaderFooterAndSidebar({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main-layout">
        <Sidebar />
        <main className="content">{children}</main>
      </div>
      {/* <Footer /> */}
    </ThemeProvider>
  );
}


export default App;
