import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { API_videoENDPOINTS } from '../../config';
import { toast } from 'react-toastify';

const GetPermalinkPrefix = ({ selectedVideo, token, permalinkSuffix, setPermalinkSuffix, setFullPermalink, errors, setErrors }) => {
    const permalinkBefore = "https://academy.beyondmoney.jp/programs/";
    const [permalinkPrefix, setPermalinkPrefix] = useState('');

    useEffect(() => {
        const createPermalink = async () => {
            if (!selectedVideo) return;

            try {
                const response = await fetch(`${API_videoENDPOINTS.permalink}?type=${selectedVideo}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('パーマリンクの作成に失敗しました');
                }
                const data = await response.json();
                setPermalinkPrefix(data.url);
                // API から取得した URL 部分をセットした後、完全なパーマリンクを親コンポーネントに返す
                setFullPermalink(`${data.url}${permalinkSuffix}`);
            } catch (error) {
                console.error("パーマリンク作成中にエラーが発生しました:", error.message);
                toast.error('パーマリンクの取得に失敗しました');
            }
        };

        createPermalink();
    }, [selectedVideo, token, permalinkSuffix, setFullPermalink]);

    const handleChange = (e) => {
        const { value } = e.target;
        setPermalinkSuffix(value);
        if (errors.permalinkSuffix && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, permalinkSuffix: '' }));
        }
    };

    return (
        <TextField
            label="パーマリンク"
            variant="outlined"
            value={permalinkSuffix}
            onChange={handleChange}
            error={!!errors.permalinkSuffix}
            helperText={errors.permalinkSuffix}
            className="PamalinkField"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {`${permalinkBefore}${permalinkPrefix}`}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default GetPermalinkPrefix;
