import React, { useState, useEffect } from 'react';
import { TagChangeBtn, CategoryDataDisplay, SearchWriter } from '../components/videos';
import { useRecoilValue } from 'recoil';
import { userState } from '../atom';
import MovieForm from './MovieForm';
import '../css/indexVideo.css';

function IndexVideo() {
    const [view, setView] = useState('indexVideos');
    const [selectedWriterId, setSelectedWriterId] = useState(null);
    const user = useRecoilValue(userState);
    const allowedRoles = ['role3']; //後でrole1を追加する
    const canViewNewPosts = allowedRoles.includes(user.role);

    useEffect(() => {
        // role1 の場合、新規投稿を選択した時にライター選択を強制する
        if (view === 'newPosts' && user.role === 'role1' && !selectedWriterId) {
            setView('selectWriter');
        }
    }, [view, user.role, selectedWriterId]);

    const handleSelectWriter = (writerId) => {
        setSelectedWriterId(writerId); // ライターIDをセット
        setView('newPosts'); // 新規投稿ビューに切り替え
    };

    return (
        <>
            <div className='tagBtnField'>
                {canViewNewPosts && <TagChangeBtn label="新規投稿" onClick={() => setView('newPosts')} />}
                <TagChangeBtn label="動画一覧" onClick={() => setView('indexVideos')} />
            </div>
            <div>
                {view === 'selectWriter' && (
                    <SearchWriter onSelectWriter={handleSelectWriter} />
                )}
                {view === 'newPosts' &&
                    <div>
                        <MovieForm />
                    </div>
                }
            </div>
            {view === 'indexVideos' &&
                <div className='categoryDataField'>
                    <CategoryDataDisplay />
                </div>
            }
        </>
    )
}

export default IndexVideo;
