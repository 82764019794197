import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
// import EventSeatIcon from '@mui/icons-material/EventSeat';
import AddHomeIcon from '@mui/icons-material/AddHome';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
// import EventIcon from '@mui/icons-material/Event';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MovieIcon from '@mui/icons-material/Movie';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';

export const SidebarData = [
    {
        title: "ホーム",
        icon: <HomeIcon />,
        link: "/home",
        roles: ["role1", "role2", "role3", "role4"],
    },
    {
        title: "ユーザ管理",
        icon: <HomeIcon />,
        link: "/users",
        roles: ["role1", "role2"],
    },
    {
        title: "新規登録",
        icon: <AddHomeIcon />,
        link: "/register",
        roles: ["role1"],
    },
    {
        title: "プロフ登録",
        icon: <AddHomeIcon />,
        link: "/registerProfile",
        roles: ["role3", "role4"],
    },
    // {
    //     title: "予約一覧",
    //     icon: <CalendarMonthIcon />,
    //     link: "/reserve",
    //     roles: ["role4"],
    // },
    // {
    //     title: "予約一覧",
    //     icon: <CalendarMonthIcon />,
    //     link: "/reserve-manage",
    //     roles: ["role1", "role2"],
    // },
    // {
    //     title: "新規予約",
    //     icon: <EditCalendarIcon />,
    //     link: "/store-reservation",
    //     roles: ["role4"],
    // },
    // {
    //     title: "新規予約",
    //     icon: <EditCalendarIcon />,
    //     link: "/store-reservation-manage",
    //     roles: ["role1", "role2"],
    // },
    // {
    //     title: "セミナー",
    //     icon: <EventIcon />,
    //     link: "/seminar",
    //     roles: ["role1", "role2","role4"],
    // },
    // {
    //     title: "セミナー作成",
    //     icon: <EventSeatIcon />,
    //     link: "/seminar-store",
    //     roles: ["role1"],
    // },
    // {
    //     title: "本日予約",
    //     icon: <CalendarTodayIcon />,
    //     link: "/oneday-reservation",
    //     roles: ["role1", "role2"],
    // },
    {
        title: "新規講座",
        icon: <MovieCreationIcon />,
        link: "/post-package",
        roles: ["role3"],
    },
    {
        title: "講座一覧",
        icon: <MovieIcon />,
        link: "/index-video",
        roles: ["role1", "role2", "role3"],
    },
];